import React, { useRef, useState } from 'react'
import { db, timestamp, auth, signInWithEmailAndPassword, signOut, storage } from '../../firestore'
import { ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage'
import { doc, collection, setDoc } from 'firebase/firestore'
import { v4 as uuidv4 } from 'uuid'
import Backdrop from '@mui/material/Backdrop'
import CircularProgress from '@mui/material/CircularProgress'
import PopAlertModaladdAuthor from '../../Components/PopAlertModaladdAuthor'
import { Dialog, Transition } from '@headlessui/react'
import AdminCustomAlert from '../AdminPageComponents/AdminCustomALert'
import AdminCustomAlertRed from '../AdminPageComponents/AdminCustomALertRed'

function TabAddAuthor() {
  const [fileUploadFailMessage, setFileUploadFailMessage] = useState(false)
  const [fileUploadSizeMessage, setFileUploadSizeMessage] = useState(false)
  const [savingDataProgressDone, setSavingDataProgressDone] = useState(false)
  const [openModalAlert, setOpenModalAlert] = useState(false)
  ///////////////////////////function passed to PopAlertModal to open and close

  const closeModalFunctionPassed = () => {
    setOpenModalAlert(false)
  }

  const [alertMessage, setAlertMessage] = useState(false)
  const [alertMessageTitle, setAlertMessageTitle] = useState(false)
  const [addSuccessAlert, setAddSuccessAlert] = useState(false)
  const [addFailAlert, setAddFailAlert] = useState(false)
  /////////////////////////
  let [invalidMessage, setInvalidMessage] = useState('')
  let [invalidMessageExtended, setInvalidMessageExtended] = useState('')

  const emailPopMessage = (
    <>
      <Dialog.Title as="h3" className="text-lg text-center font-medium leading-6 text-indigo-900">
        {invalidMessage}
      </Dialog.Title>
      <div className="mt-2">
        <p className="text-sm text-gray-500 text-right">{invalidMessageExtended}</p>
      </div>
    </>
  )

  const [enterCompanyName, setEnterCompanyName] = useState(false)
  const form = useRef()

  const companyName = useRef('')
  const companyNameRef = useRef()

  const companyEmail = useRef('')
  const companyEmailRef = useRef()

  const companyPhone = useRef('')
  const companyPhoneRef = useRef()

  const companyWeb = useRef('')
  const companyWebRef = useRef()

  const fileInputOne = useRef()
  const fileToUpload = useRef()
  const progress = useRef()
  let DataObject = {}

  const submitForm = function (e) {
    e.preventDefault()
    ////////////backdrop
    setSavingDataProgressDone(true)

    ///////////////////error messages
    setFileUploadSizeMessage(false)
    setFileUploadFailMessage(false)
    setEnterCompanyName(false)
    ///////////////////////////////////////////////Validation Section
    if (!companyName.current || companyName.current.trim().length === 0) {
      setInvalidMessage('ادخال ناقص')
      setInvalidMessageExtended('الرجاء ادخال اسم الشركة')
      setSavingDataProgressDone(false)
      setOpenModalAlert(true)
      // setEnterCompanyName(true)

      return
    }
    if (
      /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(companyEmail.current) ||
      !companyEmail.current ||
      companyEmail.current.trim().length === 0
    ) {
    } else {
      setInvalidMessage('ادخال ناقص')
      setInvalidMessageExtended('البريد الالكتروني غير صحيح')
      setSavingDataProgressDone(false)
      setOpenModalAlert(true)
      return
    }
    let re = /^[0-9]*$/

    if (
      (re.test(companyPhone.current.trim()) && companyPhone.current.length > 6) ||
      !companyPhone.current ||
      companyPhone.current.trim().length === 0
    ) {
    } else {
      setInvalidMessage('ادخال ناقص')
      setInvalidMessageExtended('رقم الهاتف غير صحيح')
      setSavingDataProgressDone(false)
      setOpenModalAlert(true)
      return
    }
    //////////////////////////////////////////////////////////////

    uploadFile()
  }

  const companyNameFunction = e => {
    companyName.current = e.target.value
    companyName.current.trim().length > 1 && setEnterCompanyName(false)
  }
  const companyEmailFunction = e => {
    companyEmail.current = e.target.value
  }
  const companyPhoneFunction = e => {
    companyPhone.current = e.target.value
  }
  const companyWebFunction = e => {
    companyWeb.current = e.target.value
  }

  const fileOneFunction = function (e) {
    fileToUpload.current = e.target.files[0]
  }
  //////////////////////upload file function
  const uploadFile = async function () {
    DataObject = {}
    //////////////data Verification
    if (!fileToUpload.current) {
      DataObject.Logo = 'https://via.placeholder.com/350'

      SendDataToFirestore()
      return
    }
    if (fileToUpload.current.size / 1024 > 2048) {
      setFileUploadSizeMessage(true)
      setSavingDataProgressDone(false)
      return
    }

    let myuuid = uuidv4()
    const storageRef = ref(storage, myuuid)
    const uploadTask = uploadBytesResumable(storageRef, fileToUpload.current)
    uploadTask.on(
      'state_changed',
      snapshot => {
        // Observe state change events such as progress, pause, and resume
        // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
        progress.current = (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        console.log('Upload is ' + progress.current + '% done')
        switch (snapshot.state) {
          case 'paused':
            console.log('Upload is paused')
            break
          case 'running':
            console.log('Upload is running')
            break
        }
      },
      error => {
        setFileUploadFailMessage(true)
        setSavingDataProgressDone(false)
        console.log(error.message)
      },
      () => {
        // Handle successful uploads on complete
        // For instance, get the download URL: https://firebasestorage.googleapis.com/...
        getDownloadURL(uploadTask.snapshot.ref).then(downloadURL => {
          console.log('File available at', downloadURL)
          DataObject.Logo = downloadURL
          SendDataToFirestore()
        })
      }
    )
  }
  const SendDataToFirestore = async function () {
    companyName.current && (DataObject.value = companyName.current.trim())
    companyName.current && (DataObject.label = companyName.current.trim())
    companyEmail.current && (DataObject.Email = companyEmail.current.trim().toLowerCase())
    companyWeb.current && (DataObject.Web = companyWeb.current.trim().toLowerCase())
    companyPhone.current && (DataObject.Phone = companyPhone.current.trim())

    const newDocRef = doc(collection(db, 'Author'))
    try {
      await setDoc(newDocRef, DataObject)

      setAlertMessageTitle('تنبيه')
      setAlertMessage('تم اضافة الملف بنجاح!')
      setAddSuccessAlert(true)
      setTimeout(() => {
        setAddSuccessAlert(false)
      }, 2500)
      form.current.reset()
      companyNameRef.current = ''
      companyWebRef.current = ''
      companyPhoneRef.current = ''
      companyEmailRef.current = ''

      companyName.current = ''
      companyName.current = ''
      companyEmail.current = ''
      companyWeb.current = ''
      companyPhone.current = ''

      fileInputOne.current.value = null

      setSavingDataProgressDone(false)

      console.log('done')
    } catch (error) {
      setAlertMessageTitle('تنبيه')
      setAlertMessage('العملية فشلت')
      setAddFailAlert(true)
      setSavingDataProgressDone(false)
      setTimeout(() => {
        setAddFailAlert(false)
      }, 2500)
    }
  }
  return (
    <>
      <PopAlertModaladdAuthor
        className="z-50"
        openModalAlert={openModalAlert}
        closeModalFunctionPassed={closeModalFunctionPassed}
      >
        {emailPopMessage}
      </PopAlertModaladdAuthor>
      <Backdrop sx={{ color: '#fff', zIndex: theme => theme.zIndex.drawer + 1 }} open={savingDataProgressDone}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <form ref={form} className="px-3">
        <div className="mb-6">
          <label htmlFor="name" className="  block mb-2 text-sm font-medium text-indigo-900 dark:text-gray-300">
            اسم الشركة
          </label>
          <input
            onChange={companyNameFunction}
            ref={companyNameRef}
            type="text"
            id="name"
            className="placeholder-gray-300 text-center shadow-sm bg-gray-50 border border-gray-300 text-green-500 text-sm rounded-lg focus:ring-[1px] focus:ring-yellow-400/50  focus:border-yellow-400/50 active:border-0  focus:border-0  block w-full p-2.5 "
            placeholder="*Required"
          />
        </div>
        <div className="mb-6">
          <label htmlFor="email" className="block mb-2 text-sm font-medium text-indigo-900 dark:text-gray-300">
            {' '}
            البريد الالكتروني
          </label>
          <input
            ref={companyEmailRef}
            onChange={companyEmailFunction}
            type="email"
            id="email"
            className="placeholder-gray-300 text-center  shadow-sm bg-gray-50 border border-gray-300 text-green-500 text-sm rounded-lg focus:ring-[1px] focus:ring-yellow-400/50  focus:border-yellow-400/50 active:border-0  focus:border-0  block w-full p-2.5  "
            placeholder="example@example.com"
          />
        </div>
        <div className="mb-6">
          <label htmlFor="phone" className="block mb-2 text-sm font-medium text-indigo-900 dark:text-gray-300">
            {' '}
            رقم التلفون
          </label>
          <input
            ref={companyPhoneRef}
            onChange={companyPhoneFunction}
            type="text"
            id="phone"
            className="placeholder-gray-300 text-center  shadow-sm bg-gray-50 border border-gray-300 text-green-500 text-sm rounded-lg focus:ring-[1px] focus:ring-yellow-400/50  focus:border-yellow-400/50 active:border-0  focus:border-0  block w-full p-2.5  "
            placeholder="0691111111"
          />
        </div>
        <div className="mb-6">
          <label htmlFor="web" className="block mb-2 text-sm font-medium text-indigo-900 dark:text-gray-300">
            {' '}
            الموقع الالكتروني
          </label>
          <input
            ref={companyWebRef}
            onChange={companyWebFunction}
            id="web"
            className="placeholder-gray-300 text-center shadow-sm bg-gray-50 border border-gray-300 text-green-500 text-sm rounded-lg focus:ring-[1px] focus:ring-yellow-400/50  focus:border-yellow-400/50 active:border-0  focus:border-0  block w-full p-2.5  "
            placeholder="www.google.com"
          />
        </div>

        <label className="block mb-2 text-sm font-medium text-indigo-900 dark:text-gray-300" htmlFor="user_avatar">
          تحميل ملف
        </label>
        <input
          ref={fileInputOne}
          onChange={fileOneFunction}
          className="mb-2 block w-full text-sm text-green-500 bg-gray-50 rounded-lg border border-gray-300 cursor-pointer dark:text-gray-400 focus:outline-none focus:border-transparent dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 outline-none focus:ring-[1px] focus:ring-yellow-400/50   active:border-0  focus:border-0 "
          aria-describedby="user_avatar_help"
          id="user_avatar"
          type="file"
          accept="image/*"
        />
      </form>{' '}
      <div className=" min-h-8  text-red-600 text-center animate-pulse duration-700">
        {fileUploadFailMessage && <div>مشكلة في تحميل الملف</div>}
        {fileUploadSizeMessage && <div>الملف اكبر من 2 ميجا</div>}
        {enterCompanyName && <div>الرجاء ادخال اسم الشركة</div>}
      </div>
      {addSuccessAlert && <AdminCustomAlert alertMessage={alertMessage} alertMessageTitle={alertMessageTitle} />}
      {addFailAlert && <AdminCustomAlertRed alertMessage={alertMessage} alertMessageTitle={alertMessageTitle} />}
      <button
        onClick={submitForm}
        className="text-white bg-indigo-900 hover:bg-indigo-800 hover:scale-[1.01] transition focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5  dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
      >
        حفظ
      </button>
    </>
  )
}

export default TabAddAuthor
