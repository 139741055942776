import React from 'react'
import { motion } from 'framer-motion'
import { AiFillFileAdd } from 'react-icons/ai'

function TabFiles({ cardData, closeFucntion }) {
  const styleObject2 = {
    display: 'inline',
    verticalAlign: 'middle',
    fill: '#CF2020',
    height: '1.5em',
    width: '1.5em',
  }

  return (
    <>
      <motion.div initial={{ x: '-10px', opacity: 0 }} transition={{ duration: 0.6 }} animate={{ x: 0, opacity: 1 }}>
        <div className=" flex  justify-center items-center p-5 rounded-t border-b border-yellow-300 ">
          <div className="font-semibold text-indigo-900   text-2xl"> الملفات المرفقة</div>
        </div>

        <div className="flex justify-around items-center m-y-auto h-[300px] text-center">
          {cardData.FileOne && (
            <a href={cardData.FileOne} target="_blank">
              <AiFillFileAdd className="h-[100px] w-[100px]"></AiFillFileAdd>
              File One
            </a>
          )}
          {cardData.FileTwo && (
            <a href={cardData.FileTwo} target="_blank">
              <AiFillFileAdd className="h-[100px] w-[100px]"></AiFillFileAdd>
              File Two
            </a>
          )}
        </div>
        <div className="flex justify-center items-center p-6 space-x-2 rounded-b border-t border-yellow-300">
          <button
            onClick={closeFucntion}
            type="button"
            className="text-white bg-indigo-900 hover:bg-indigo-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
          >
            اغلاق
          </button>
        </div>
      </motion.div>
    </>
  )
}

export default TabFiles
