import { Dialog, Transition } from '@headlessui/react'
import { Fragment } from 'react'

export default function MyModal(props) {
  const { closeModalFunctionPassed, deleteOrUpdateAuthorFunction, openModalAlert } = props
  let Children = props.children
  return (
    <>
      <Transition appear show={openModalAlert} as={Fragment}>
        <Dialog as="div" className="fixed inset-0 z-10 overflow-y-auto" onClose={closeModalFunctionPassed}>
          <div className="min-h-screen px-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span className="inline-block h-screen align-middle" aria-hidden="true">
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <div className="inline-block w-full max-w-md p-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl">
                {Children}

                <div className="mt-4">
                  <button
                    onClick={deleteOrUpdateAuthorFunction}
                    type="button"
                    className="inline-flex justify-center px-4 py-2 text-sm font-medium text-white bg-indigo-900 hover:bg-indigo-800 hover:scale-[1.01] transition   focus:outline-none    rounded-lg  w-full sm:w-auto   "
                  >
                    Ok
                  </button>
                  <button
                    type="button"
                    className="inline-flex ml-2 justify-center px-4 py-2 text-sm font-medium text-white bg-indigo-900 hover:bg-indigo-800 hover:scale-[1.01] transition   focus:outline-none    rounded-lg  w-full sm:w-auto   "
                    onClick={closeModalFunctionPassed}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
    </>
  )
}
