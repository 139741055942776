import React, { useEffect, useRef, useState } from 'react'
import { auth, signInWithEmailAndPassword, signOut } from '../firestore'
import { useNavigate } from 'react-router-dom'

export default function Login() {
  let nav = useNavigate()
  const email = useRef()
  const password = useRef()

  const [error, setError] = useState(false)
  const submitFucntion = async e => {
    e.preventDefault()
    signInWithEmailAndPassword(
      auth,

      email.current.replace(/ /g, ''),
      password.current
    )
      .then(userCredential => {
        // Signed in

        nav('/Admin')

        setError(false)

        // ...
      })
      .catch(error => {
        setError(true)

        // const errorCode = error.code
        // const errorMessage = error.message
      })
  }
  const logOutFucntion = async e => {
    e.preventDefault()

    try {
      await signOut(auth)
    } catch (error) {
      return 1
    }
  }
  const emailFunction = e => {
    email.current = e.target.value
    setError(() => {
      return error === true ? false : null
    })
  }
  const passwordFunction = e => {
    password.current = e.target.value
    setError(() => {
      return error === true ? false : null
    })
  }

  return (
    <>
      <div className="grid place-content-center min-h-screen">
        <form>
          <div className="group relative z-0 mb-6 w-full">
            <input
              onChange={emailFunction}
              ref={email}
              type="email"
              name="floating_email"
              className="peer block w-full appearance-none border-0 border-b-2 border-gray-300 bg-transparent py-2.5 px-0 text-sm text-gray-900 focus:border-yellow-600 focus:outline-none focus:ring-0 dark:border-gray-600 dark:text-white dark:focus:border-yellow-500"
              placeholder=" "
              required
            />
            <label
              htmlFor="floating_email"
              className="absolute top-3 -z-10 origin-[0] -translate-y-6 scale-75 transform text-sm text-gray-500 duration-300 peer-placeholder-shown:translate-y-0 peer-placeholder-shown:scale-100 peer-focus:left-0 peer-focus:-translate-y-6 peer-focus:scale-75 peer-focus:text-blue-600 dark:text-gray-400 peer-focus:dark:text-blue-500"
            >
              Email address
            </label>
          </div>
          <div className="group relative z-0 mb-6 w-full">
            <input
              onChange={passwordFunction}
              type="password"
              name="floating_password"
              id="floating_password"
              className="peer block w-full appearance-none border-0 border-b-2 border-gray-300 bg-transparent py-2.5 px-0 text-sm text-gray-900 focus:border-yellow-600 focus:outline-none focus:ring-0 dark:border-gray-600 dark:text-white dark:focus:border-yellow-500"
              placeholder=" "
              required
            />
            <label
              htmlFor="floating_password"
              ref={password}
              className="absolute top-3 -z-10 origin-[0] -translate-y-6 scale-75 transform text-sm text-gray-500 duration-300 peer-placeholder-shown:translate-y-0 peer-placeholder-shown:scale-100 peer-focus:left-0 peer-focus:-translate-y-6 peer-focus:scale-75 peer-focus:text-yellow-600 dark:text-gray-400 peer-focus:dark:text-yellow-500"
            >
              Password
            </label>
          </div>
          <div className="  flex items-center flex-col ">
            <div className=" min-h-6">{error && <h5 className=" mb-2 text-red-600">invalid-email or password</h5>}</div>
            <button
              onClick={submitFucntion}
              className="p-2 px-4 w-full bg-indigo-900 outline-none focus:ring active:ring active:ring-red-600 focus:ring-red-600/50 rounded text-white hover:bg-yellow-500 hover:scale-[1.05] transition-all duration-300"
            >
              Login
            </button>
          </div>
        </form>
      </div>
    </>
  )
}
