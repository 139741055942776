// import styles from "../Styles/Card.module.scss";
import '../Styles/Card.scss'
import { BiCurrentLocation } from 'react-icons/bi'
import { VscTypeHierarchy } from 'react-icons/vsc'
import { BsCalendarCheck } from 'react-icons/bs'
import { BsFillCalendarXFill } from 'react-icons/bs'
import { RiMoneyDollarCircleLine } from 'react-icons/ri'
import { FaHashtag } from 'react-icons/fa'

import React from 'react'

export default function Card(props) {
  const { data } = props
  const { timestamp, Expiry, Title } = data
  let titleAfterArray = Title.join(' ')

  const publishDate = new Date(timestamp.toDate().toDateString()).toLocaleDateString('ar-EG', {
    weekday: 'long',
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  })

  const expiry = new Date(Expiry.toDate().toDateString()).toLocaleDateString('ar-EG', {
    weekday: 'long',
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  })

  const styleObject = {
    display: 'inline',
    verticalAlign: 'bottom',
    fill: '#CF2020',
    height: '1.5em',
    width: '1.5em',
  }
  const styleObject2 = {
    display: 'inline',
    verticalAlign: 'middle',
    fill: '#CF2020',
    height: '1.5em',
    width: '1.5em',
  }
  ///////////function to send data using the fuction sent by props
  const sendData = function () {
    props.clickedCardData(data)
  }

  return (
    <div
      onClick={sendData}
      className="rounded-md border-primary border-2 card-main shadow-md mx-auto "
      style={{ maxWidth: '600px', minHeight: '200px' }}
    >
      <div className="divider-mine"></div>

      <div className="grid grid-cols-4 gap-4">
        <div className="text-center mt-4 font-semibold md:font-semibold title col-start-2 col-span-2  overflow-hidden ">
          {titleAfterArray.length > 20 ? titleAfterArray.substring(0, 20) + '...' : titleAfterArray}
        </div>
        <div className="mt-4 col-end-5 md:self-end md:justify-self-end justify-self-center  mr-4 md:mr-12  title text-xs leading-5">
          {data.City[0]} <BiCurrentLocation style={styleObject} />{' '}
        </div>
      </div>
      <div className="dividertest my-4"></div>

      <div className="wrapper grid md:grid-cols-2 grid-cols-1 grid-rows-3 gap-2 pb-2">
        <div className="left md:justify-self-end justify-self-center mr-4 md:mr-14 md:self-end md:col-start-2 md:row-start-1">
          <span className="text-xs small-text-my-class-title">نوع العطاء</span>
          <span className="mx-1 small-text-my-class-title">-:</span>
          <span className="font-semibold small-text-my-class">{data.Type[0]}</span>
          <span className="text-xs small-text-my-class-title ml-1 ">
            <VscTypeHierarchy style={styleObject2} />
          </span>
        </div>
        <div className="left md:justify-self-end justify-self-center mr-4 md:mr-14 self-center md:col-start-2 md:row-start-2">
          <span className="text-xs small-text-my-class-title"> تاريخ النشر</span>

          <span className="mx-1 small-text-my-class-title">-:</span>
          <span className="font-semibold small-text-my-class">{publishDate}</span>
          <span className="text-xs small-text-my-class-title ml-1">
            <BsCalendarCheck style={styleObject2} />
          </span>
        </div>
        <div className="left md:justify-self-end justify-self-center mr-4 md:mr-14 self-start md:col-start-2 md:row-start-3">
          {' '}
          <span className="text-xs small-text-my-class-title"> تاريخ الانتهاء </span>
          <span className="mx-1 small-text-my-class-title">-:</span>
          <span className="font-semibold small-text-my-class">{expiry}</span>
          <span className="text-xs small-text-my-class-title ml-1">
            <BsFillCalendarXFill style={styleObject2} />
          </span>
        </div>
        <div className="left  md:justify-self-end justify-self-center mr-4 md:mr-14 md:self-end md:col-start-1 md:row-start-1">
          {' '}
          <span className="text-xs small-text-my-class-title">الجهة الممولة</span>
          <span className="mx-1 small-text-my-class-title">-:</span>
          <span className="font-semibold small-text-my-class">{data.Section[0]}</span>
          <span className="text-xs small-text-my-class-title ml-1">
            <RiMoneyDollarCircleLine style={styleObject2} />
          </span>
        </div>
        <div className="left md:justify-self-end justify-self-center mr-4 md:mr-14 self-center md:col-start-1 md:row-start-2">
          {' '}
          <span className="text-xs small-text-my-class-title">رقم العطاء</span>{' '}
          <span className="mx-1 small-text-my-class-title">-:</span>
          <span className="font-semibold small-text-my-class">{data.Number}</span>
          <span className="text-xs small-text-my-class-title ml-1">
            <FaHashtag style={styleObject2} />
          </span>
        </div>
      </div>
    </div>
  )
}
