import { Route, Routes } from 'react-router-dom'
import Admin from './Pages/Admin'
import ContactUs from './Pages/ContactUs'
import Home from './Pages/Home'
import NavBar from './Components/NavBar'
import AllBids from './Pages/Bids/AllBids'
import BridgesBids from './Pages/Bids/BridgesBids'
import ElectricityBids from './Pages/Bids/ElectricityBids'
import SpecialBids from './Pages/Bids/SpecialBids'
import WaterBids from './Pages/Bids/WaterBids'
import BuildingsBids from './Pages/Bids/BuildingsBids'
import Login from './Pages/Login'
import { useLocation } from 'react-router-dom'
import { auth } from './firestore'
import { useEffect, useState, useRef } from 'react'
import { async } from '@firebase/util'
import { onAuthStateChanged } from 'firebase/auth'
import Footer from './Components/Footer'

function App() {
  const location = useLocation()
  const [AdminPage, setAdminPage] = useState('')
  const [userAuth, setUserAuth] = useState(false)
  const userId = useRef()

  onAuthStateChanged(auth, user => {
    if (user) {
      if (userId.current == true) {
        return
      } else {
        userId.current = true
      }

      setAdminPage(<Route path="/Admin" element={<Admin />} />)
    } else {
      if (userId.current == false) {
        return
      } else {
        userId.current = false
      }
    }
  })

  useEffect(() => {
    if (userId.current) {
      setAdminPage(<Route path="/Admin" element={<Admin />} />)
    } else {
      setAdminPage(<Route path="/Admin" element={<Login />} />)
    }
  }, [userId.current])

  return (
    <>
      <NavBar />

      <Routes location={location} key={location.key}>
        <Route path="/" element={<Home />} />
        {AdminPage && AdminPage}

        <Route path="/ContactUs" element={<ContactUs />} />
        <Route path="/AllBids" element={<AllBids />} />
        <Route path="/BridgesBids" element={<BridgesBids />} />
        <Route path="/ElectricityBids" element={<ElectricityBids />} />
        <Route path="/SpecialBids" element={<SpecialBids />} />
        <Route path="/WaterBids" element={<WaterBids />} />
        <Route path="/BuildingsBids" element={<BuildingsBids />} />
        <Route path="/Login" element={<Login />} />
      </Routes>
      <Footer />
    </>
  )
}

export default App
