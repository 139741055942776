import React, { useState, useEffect, useRef } from 'react'
import SelectMenuAuthorAdmin from '../../Components/SelectMenuAuthorAdmin'
import Backdrop from '@mui/material/Backdrop'
import CircularProgress from '@mui/material/CircularProgress'
import SelectMenuAdminCity from '../SelectMenuAdminCity'
import SelectMenuAdminType from '../SelectMenuAdminType'
import SelectMenuAdminSection from '../SelectMenuAdminSection'
import SelectMenuAdminStatus from '../SelectMenuAdminStatus'
import PopAlertModaladdAuthor from '../../Components/PopAlertModaladdAuthor'
import { Dialog } from '@headlessui/react'
import { AiOutlineCloseCircle } from 'react-icons/ai'
import { ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage'
import { v4 as uuidv4 } from 'uuid'
import { db, timestamp, auth, signInWithEmailAndPassword, signOut, storage } from '../../firestore'
import AdminCustomAlert from '../AdminPageComponents/AdminCustomALert'
import AdminCustomAlertRed from '../AdminPageComponents/AdminCustomALertRed'

import {
  collection,
  addDoc,
  getDocs,
  query,
  where,
  orderBy,
  limit,
  startAfter,
  doc,
  deleteDoc,
  setDoc,
} from 'firebase/firestore'

function TabAddBid() {
  const [details, setDetails] = useState('')
  const [expiry, setExpiry] = useState('')

  const [title, setTitle] = useState('')
  const [number, setNumber] = useState('')
  const [dataArray, setDataArray] = useState([])
  const [selectParent, setSelectParent] = useState({})
  const [selectedCity, setSelectedCity] = useState({})
  const [selectedType, setSelectedType] = useState({})
  const [selectedSection, setSelectedSection] = useState({})
  const [selectedStatus, setSelectedStatus] = useState({})

  const [resetStatus, setResetStatus] = useState(false)
  const [resetSection, setResetSection] = useState(false)
  const [resetType, setResetType] = useState(false)

  const [resetCity, setResetCity] = useState(false)

  const resetStatusFunction = function () {
    setResetStatus(false)
  }
  const resetTypeFunction = function () {
    setResetType(false)
  }
  const resetCityFunction = function () {
    setResetCity(false)
  }
  const resetSectionFunction = function () {
    setResetSection(false)
  }

  const [alertMessage, setAlertMessage] = useState(false)
  const [alertMessageTitle, setAlertMessageTitle] = useState(false)
  const [addSuccessAlert, setAddSuccessAlert] = useState(false)
  const [addFailAlert, setAddFailAlert] = useState(false)

  const [fileInputOne, setFileInputOne] = useState('')
  const [fileInputTwo, setFileInputTwo] = useState('')
  const fileInputOneRef = useRef()
  const fileInputTwoRef = useRef()
  let dataToSave = {}
  const dateField = useRef()

  const [fileUploadFailMessage, setFileUploadFailMessage] = useState(false)
  const [fileUploadSizeMessage, setFileUploadSizeMessage] = useState(false)
  const [progress, setProgress] = useState(0)

  const [initialDataLoadingDone, setInitialDataLoadingDone] = useState(false)
  const [loadingBackDrop, setLoadingBackDrop] = useState(false)

  const [openModalAlert, setOpenModalAlert] = useState(false)

  let [invalidMessage, setInvalidMessage] = useState('')
  let [invalidMessageExtended, setInvalidMessageExtended] = useState('')

  const q = useRef('')

  const emailPopMessage = (
    <>
      <Dialog.Title as="h3" className="text-lg text-center font-medium leading-6 text-indigo-900">
        {invalidMessage}
      </Dialog.Title>
      <div className="mt-2">
        <p className="text-sm text-gray-500 text-right">{invalidMessageExtended}</p>
      </div>
    </>
  )
  ////////////////////////////////Modal close function
  const closeModalFunctionPassed = () => {
    setOpenModalAlert(false)
  }

  ////////////////////function passed to parent to change selected value
  const changeSelected = function (selection) {
    setSelectParent(selection)
  }
  const changeSelectedCity = function (selection) {
    setSelectedCity(selection)
    console.log(selection)
  }
  const changeSelectedType = function (selection) {
    setSelectedType(selection)
    console.log(selection)
  }
  const changeSelectedSection = function (selection) {
    setSelectedSection(selection)
    console.log(selection)
  }
  const changeSelectedStatus = function (selection) {
    setSelectedStatus(selection)
    console.log(selection)
  }
  ///////////////////////////////Form submit handling
  const submitHandler = function (e) {
    e.preventDefault()
    setFileUploadSizeMessage(false)

    if (
      title.trim().length === 0 ||
      !selectParent.value ||
      !selectedCity.value ||
      !selectedSection.value ||
      !selectedType.value ||
      !selectedStatus.value
    ) {
      setInvalidMessage('تنبيه')
      setInvalidMessageExtended('كل البيانات مطلوبة عدا التفاصيل و تحميل الملفات')
      setOpenModalAlert(true)
      return
    }
    setLoadingBackDrop(true)
    console.log(selectParent)
    dataToSave = {
      Title: title.split(' '),
      Author: selectParent.value,
      City: [selectedCity.value, 'all'],
      Section: [selectedSection.value, 'all'],
      Type: [selectedType.value, 'all'],
      Status: [selectedStatus.value, 'all'],
      Subject: details,
      Number: number,
      Expiry: new Date(expiry),
      User: auth.currentUser.email,
      timestamp: timestamp,
    }
    if (!fileInputOne & !fileInputTwo) {
      sendDataFunction()
    } else {
      upoadFilesFunction()
    }
  }
  useEffect(() => {
    console.log(fileInputOne)
  }, [fileInputOne])
  /////////////////useEffect call All author Data form DB
  useEffect(() => {
    if (initialDataLoadingDone) {
      return
    }
    const firstCall = async function () {
      q.current = query(collection(db, 'Author'), where('value', '!=', ''), orderBy('value', 'desc'))
      //
      let querySnapshot = await getDocs(q.current)

      let tempDataArray = []
      querySnapshot.forEach(doc => {
        let data = { ...doc.data(), Id: doc.id }

        tempDataArray.push(data)
      })

      setInitialDataLoadingDone(true)

      setDataArray([...tempDataArray])
    }

    firstCall()

    return () => {}
  }, [initialDataLoadingDone])
  ////////////////////////////////////////// sendData function

  const sendDataFunction = async () => {
    // console.log(dataToSave)

    console.log('datasent')
    try {
      const docRef = await addDoc(collection(db, 'Bids'), dataToSave)
      console.log('Document written with ID: ', docRef.id)
      setAlertMessageTitle('تنبيه')
      setAlertMessage('تم اضافة الملف بنجاح!')
      setAddSuccessAlert(true)
      setLoadingBackDrop(false)

      /////resetting values in this page
      setTitle('')
      setNumber('')
      setSelectParent('')
      setSelectedCity('')
      setSelectedType('')
      setSelectedSection('')
      setSelectedStatus('')
      setDetails('')
      ////resetting files
      setFileInputOne('')
      setFileInputTwo('')
      ///resetting files input
      fileInputOneRef.current.value = null
      fileInputTwoRef.current.value = null

      ///////resetting date
      dateField.current.value = ''
      /////////////////resetting values form select menu in child
      setInitialDataLoadingDone(false)
      setResetCity(true)
      setResetSection(true)
      setResetType(true)
      setResetStatus(true)

      setTimeout(() => {
        setAddSuccessAlert(false)
      }, 2500)
    } catch (error) {
      setAlertMessageTitle('تنبيه')
      setAlertMessage('العملية فشلت')
      setAddFailAlert(true)
      setLoadingBackDrop(false)
      setTimeout(() => {
        setAddFailAlert(false)
      }, 2500)
    }
  }

  const upoadFilesFunction = async function () {
    setLoadingBackDrop(true)
    //////////////data Verification

    if (fileInputOne.size / 1024 > 2048 || fileInputTwo.size / 1024 > 2048) {
      setFileUploadSizeMessage(true)
      setLoadingBackDrop(false)
      return
    }
    let myuuid = uuidv4()
    const storageRef = ref(storage, myuuid)
    const uploadTask = uploadBytesResumable(storageRef, fileInputOne)
    uploadTask.on(
      'state_changed',
      snapshot => {
        // Observe state change events such as progress, pause, and resume
        // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
        setProgress((snapshot.bytesTransferred / snapshot.totalBytes) * 100)
        console.log('Upload is ' + progress + '% done')
        switch (snapshot.state) {
          case 'paused':
            console.log('Upload is paused')
            break
          case 'running':
            console.log('Upload is running')
            break
        }
      },
      error => {
        setFileUploadFailMessage(true)
        setLoadingBackDrop(false)
        console.log(error.message)
      },
      () => {
        // Handle successful uploads on complete
        // For instance, get the download URL: https://firebasestorage.googleapis.com/...
        getDownloadURL(uploadTask.snapshot.ref).then(downloadURL => {
          console.log('File available at', downloadURL)
          dataToSave.FileOne = downloadURL
          if (!fileInputTwo) {
            sendDataFunction()
          } else {
            if (fileInputTwo) {
              let myuuid = uuidv4()
              const storageRef = ref(storage, myuuid)
              const uploadTask = uploadBytesResumable(storageRef, fileInputOne)
              uploadTask.on(
                'state_changed',
                snapshot => {
                  // Observe state change events such as progress, pause, and resume
                  // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
                  setProgress((snapshot.bytesTransferred / snapshot.totalBytes) * 100)
                  console.log('Upload is ' + progress + '% done')
                  switch (snapshot.state) {
                    case 'paused':
                      console.log('Upload is paused')
                      break
                    case 'running':
                      console.log('Upload is running')
                      break
                  }
                },
                error => {
                  setFileUploadFailMessage(true)
                  setLoadingBackDrop(false)
                  console.log(error.message)
                },
                () => {
                  // Handle successful uploads on complete
                  // For instance, get the download URL: https://firebasestorage.googleapis.com/...
                  getDownloadURL(uploadTask.snapshot.ref).then(downloadURL => {
                    console.log('File available at', downloadURL)
                    dataToSave.FileTwo = downloadURL
                    sendDataFunction()
                  })
                }
              )
            }
          }
        })
      }
    )
  }

  return (
    <>
      {' '}
      <PopAlertModaladdAuthor
        className="z-50"
        openModalAlert={openModalAlert}
        closeModalFunctionPassed={closeModalFunctionPassed}
      >
        {emailPopMessage}
      </PopAlertModaladdAuthor>
      <Backdrop sx={{ color: '#fff', zIndex: theme => theme.zIndex.drawer + 1 }} open={loadingBackDrop}>
        <div className=" absolute top-[60%] left-[48%] z-10">
          <CircularProgress color="inherit" />
          {parseFloat(progress).toFixed(1) + '%'}
        </div>
      </Backdrop>
      <Backdrop sx={{ color: '#fff', zIndex: theme => theme.zIndex.drawer + 1 }} open={!initialDataLoadingDone}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <form className="px-3" onSubmit={submitHandler}>
        <div className="mb-6">
          <label htmlFor="title" className="block mb-2 text-sm font-medium text-indigo-900 dark:text-gray-300">
            العنوان{' '}
          </label>
          <input
            value={title}
            onChange={e => {
              ;(title.match(/^\s*$/) || []).length > 0 ? setTitle(e.target.value.trim()) : setTitle(e.target.value)
            }}
            type="text"
            id="title"
            className="placeholder-gray-300 text-center shadow-sm bg-gray-50 border border-gray-300 text-green-500 text-sm rounded-lg focus:ring-[1px] focus:ring-yellow-400/50  focus:border-yellow-400/50 active:border-0  focus:border-0  block w-full p-2.5"
            placeholder="عنوان"
            required
          />
        </div>
        <div className="mb-6">
          <label htmlFor="number" className="block mb-2 text-sm font-medium text-indigo-900 dark:text-gray-300">
            رقم العطاء{' '}
          </label>
          <input
            value={number}
            onChange={e => {
              ;(number.match(/^\s*$/) || []).length > 0 ? setNumber(e.target.value.trim()) : setNumber(e.target.value)
            }}
            type="text"
            id="number"
            className="placeholder-gray-300 text-center shadow-sm bg-gray-50 border border-gray-300 text-green-500 text-sm rounded-lg focus:ring-[1px] focus:ring-yellow-400/50  focus:border-yellow-400/50 active:border-0  focus:border-0  block w-full p-2.5"
            placeholder="999999"
            required
          />
        </div>
        <label htmlFor="type" className="block mb-2 text-sm font-medium text-indigo-900 dark:text-gray-400">
          نوع العطاء
        </label>
        <SelectMenuAdminType
          resetType={resetType}
          resetTypeFunction={resetTypeFunction}
          changeSelectedType={changeSelectedType}
        ></SelectMenuAdminType>
        <label
          htmlFor="selectedAuthor"
          className="mt-6 block mb-2 text-sm font-medium text-indigo-900 dark:text-gray-400"
        >
          الناشر
        </label>
        <SelectMenuAuthorAdmin
          id="selectedAuthor"
          dataArray={dataArray}
          changeSelected={changeSelected}
        ></SelectMenuAuthorAdmin>
        <label htmlFor="city" className="mt-6 block mb-2 text-sm font-medium text-indigo-900 dark:text-gray-400">
          المدينة
        </label>
        <SelectMenuAdminCity
          resetCity={resetCity}
          resetCityFunction={resetCityFunction}
          changeSelectedCity={changeSelectedCity}
        ></SelectMenuAdminCity>
        <label htmlFor="section" className="mt-6 block mb-2 text-sm font-medium text-indigo-900 dark:text-gray-400">
          القطاع
        </label>
        <SelectMenuAdminSection
          resetSection={resetSection}
          resetSectionFunction={resetSectionFunction}
          changeSelectedSection={changeSelectedSection}
        ></SelectMenuAdminSection>
        <label htmlFor="expired" className="mt-6 block mb-2 text-sm font-medium text-indigo-900 dark:text-gray-400">
          تاريخ انتهاء التقديم
        </label>
        <input
          ref={dateField}
          className=" relative left-3 z-[1] placeholder-gray-300 text-center shadow-sm bg-gray-50 border border-gray-300 text-green-500 text-sm rounded-lg focus:ring-[1px] focus:ring-yellow-400/50  focus:border-yellow-400/50 active:border-0  focus:border-0  block mx-auto p-2.5 w-1/2"
          type="date"
          name="expired"
          id=""
          onChange={e => setExpiry(e.target.value)}
          required
        />
        <label htmlFor="details" className="mt-6 block mb-2 text-sm font-medium text-indigo-900 dark:text-gray-400">
          الحالة
        </label>
        <SelectMenuAdminStatus
          resetStatus={resetStatus}
          resetStatusFunction={resetStatusFunction}
          changeSelectedStatus={changeSelectedStatus}
        ></SelectMenuAdminStatus>
        <label htmlFor="details" className="mt-6 block mb-2 text-sm font-medium text-indigo-900 dark:text-gray-400">
          التفاصيل
        </label>
        <textarea
          className="relative left-3 z-[3] placeholder-gray-300   shadow-sm bg-gray-50 border border-gray-300 text-green-500 text-sm rounded-lg focus:ring-[1px] focus:ring-yellow-400/50  focus:border-yellow-400/50 active:border-0  focus:border-0  block mx-auto p-2.5 w-1/2 text-right"
          id="details"
          name="details"
          rows="4"
          cols="50"
          type="text"
          value={details}
          onChange={e => {
            ;(details.match(/^\s*$/) || []).length > 0 ? setDetails(e.target.value.trim()) : setDetails(e.target.value)
          }}
        ></textarea>
        <label className="mt-6 block mb-2 text-sm font-medium text-indigo-900 dark:text-gray-400">
          الملفات المرفقة
        </label>
        <div className="relative">
          <input
            ref={fileInputOneRef}
            onChange={e => setFileInputOne(e.target.files[0])}
            className=" disabled:bg-gray-700/50 mb-2 block w-full text-sm text-green-500 bg-gray-50 rounded-lg border border-gray-300 cursor-pointer dark:text-gray-400 focus:outline-none focus:border-transparent dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 outline-none focus:ring-[1px] focus:ring-yellow-400/50   active:border-0  focus:border-0 "
            aria-describedby="user_avatar_help"
            id="file-two"
            type="file"
            accept="application/msword, application/vnd.ms-excel, application/vnd.ms-powerpoint,
          text/plain, application/pdf, image/*"
          />{' '}
          <button
            type="button"
            onClick={() => {
              setFileInputOne()
              setFileInputTwo()
              fileInputOneRef.current.value = null
              fileInputTwoRef.current.value = null
            }}
            className="absolute top-[1px] right-0 h-9  text-white bg-indigo-900 hover:bg-indigo-800 hover:scale-[1.01] transition focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5  dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
          >
            <AiOutlineCloseCircle></AiOutlineCloseCircle>
          </button>
        </div>
        <div className="relative">
          <input
            ref={fileInputTwoRef}
            onChange={e => setFileInputTwo(e.target.files[0])}
            className=" disabled:bg-gray-700/50 mb-2 block w-full text-sm text-green-500 bg-gray-50 rounded-lg border border-gray-300 cursor-pointer dark:text-gray-400 focus:outline-none focus:border-transparent dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 outline-none focus:ring-[1px] focus:ring-yellow-400/50   active:border-0  focus:border-0 "
            aria-describedby="user_avatar_help"
            id="file-two"
            type="file"
            disabled={!fileInputOne}
            accept="application/msword, application/vnd.ms-excel, application/vnd.ms-powerpoint,
          text/plain, application/pdf, image/*"
          />{' '}
          <button
            type="button"
            onClick={() => {
              setFileInputTwo()
              fileInputTwoRef.current.value = null
            }}
            className="absolute top-[1px] right-0 h-9  text-white bg-indigo-900 hover:bg-indigo-800 hover:scale-[1.01] transition focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5  dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
          >
            <AiOutlineCloseCircle></AiOutlineCloseCircle>
          </button>
        </div>
        <div className=" min-h-8  text-red-600 text-center animate-pulse duration-700">
          {fileUploadFailMessage && <div>مشكلة في تحميل الملف</div>}
          {fileUploadSizeMessage && <div>الملف اكبر من 2 ميجا</div>}
        </div>
        {addSuccessAlert && <AdminCustomAlert alertMessage={alertMessage} alertMessageTitle={alertMessageTitle} />}
        {addFailAlert && <AdminCustomAlertRed alertMessage={alertMessage} alertMessageTitle={alertMessageTitle} />}
        <button
          type="submit"
          className="mt-4 text-white bg-indigo-900 hover:bg-indigo-800 hover:scale-[1.01] transition focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5  dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
        >
          اضافة العطاء
        </button>
      </form>
    </>
  )
}

export default TabAddBid
