import React, { useState, useEffect, useRef } from 'react'
import Backdrop from '@mui/material/Backdrop'
import CircularProgress from '@mui/material/CircularProgress'
import PopAlertModalDeleteConfirm from '../../Components/PopAlertModalDeleteConfirm'
import AdminCustomAlert from '../AdminPageComponents/AdminCustomALertV2'
import SelectMenuAdminCity from '../SelectMenuAdminCity'
import SelectMenuAdminType from '../SelectMenuAdminType'
import SelectMenuAdminSection from '../SelectMenuAdminSection'
import SelectMenuAdminStatus from '../SelectMenuAdminStatus'
import PopAlertModaladdAuthor from '../../Components/PopAlertModaladdAuthor'
import { Dialog } from '@headlessui/react'
import { AiOutlineCloseCircle } from 'react-icons/ai'
import { ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage'
import { v4 as uuidv4 } from 'uuid'
import { db, timestamp, auth, signInWithEmailAndPassword, signOut, storage } from '../../firestore'
import { collection, getDocs, query, where, orderBy, limit, startAfter, deleteDoc, doc } from 'firebase/firestore'

import EditBidModal from '../EditBidModal'
import CardAdmin from '../../Components/CardAdmin.jsx'
import { CSSTransition } from 'react-transition-group'
import TabBids from '../../Components/TabBids.jsx'
import TabAuthor from '../../Components/TabAuthor.jsx'
import TabFiles from '../../Components/TabFiles.jsx'

function TabEditDelBid() {
  const [fromDate, setFromDate] = useState()

  const [toDate, setToDate] = useState()
  const [errorDateLineup, setErrorDateLineup] = useState()
  const [errorDateLineupMessage, setErrorDateLineupMessage] = useState()

  const [loadingData, setLoadingData] = useState(false)
  const [dataArray, setDataArray] = useState([])
  /////////data array fro author

  let [getMoreData, setGetMoreData] = useState(false)
  let [noMoreData, setNoMoreData] = useState(false)
  let [getDataLoading, setGetDataLoading] = useState(false)
  let [cardData, setCardData] = useState({})

  let [showModal, setShowModal] = useState(false)
  let [firebaseError, setFirebaseError] = useState(false)
  const [expiredGlow, setExpiredGlow] = useState()
  let [cardAuthorData, setCardAuthorData] = useState({})
  let [publishDate, setPublishDate] = useState()
  let [expiry, setExpiry] = useState()
  let modalButton = useRef()
  const fromDateRef = useRef()
  const toDateRef = useRef()
  const q = useRef()
  const limitCount = useRef(20)
  let lastVisible = useRef()
  let [tabBids, setTabBids] = useState(1)
  let modalBackdrop = useRef()
  const [openEditModal, setOpenEditModal] = useState(false)
  const [deleteFinished, setDeleteFinished] = useState([false, ''])
  const [deleteFinishedSucess, setDeleteFinishedSuccess] = useState([false, ''])
  ///////////////////showModal for Edit Modal
  const [showEditModal, setShowEditModal] = useState(false)
  const [alertMessage, setAlertMessage] = useState(false)
  const [alertMessageTitle, setAlertMessageTitle] = useState(false)
  const [alertMessageSucOrFailSwitch, setAlertMessageSucOrFailSwitch] = useState(1)

  const [deletedAlert, setDeletedAlert] = useState(false)
  const [editModalDataToSendToModal, setEditModalDataToSendToModal] = useState()
  let alertMessagetitle = ''
  let alertMessageSubject = ''
  //////////////////////////////////////////////tabsToggleFunction passed to tabs

  const bidTabsToggleFunction = () => {
    setTabBids(1)
  }
  const authorTabsToggleFunction = () => {
    setTabBids(2)
  }
  const filesTabsToggleFunction = () => {
    setTabBids(3)
  }
  ////////////////////////function to close the modal by click Eghla2 or X sign////////// inside clicked Card
  const closeFucntion = () => {
    setTabBids(1)
    setShowModal(false)
  }
  ////////////////////////function to retireve data from the card to show in module popup
  const clickedCardData = function (data) {
    setShowModal(true)

    setPublishDate(
      new Date(data.timestamp.toDate().toDateString()).toLocaleDateString('ar-EG', {
        weekday: 'long',
        year: 'numeric',
        month: 'long',
        day: 'numeric',
      })
    )

    setExpiry(
      new Date(data.Expiry.toDate().toDateString()).toLocaleDateString('ar-EG', {
        weekday: 'long',
        year: 'numeric',
        month: 'long',
        day: 'numeric',
      })
    )

    setCardData(() => {
      return data
    })

    let y = new Date(Date.now())
    let g = new Date(data.Expiry.toDate())
    let x = y > g ? true : false
    setExpiredGlow(() => {
      return x
    })

    ////////////////////////function to to get author data and pass it to TabAuthor ////////// inside clicked Card
    const getAuthor = async function () {
      q.value = query(collection(db, 'Author'), where('value', '==', data.Author))
      //
      let querySnapshot = await getDocs(q.value)
      let dataArray = []

      querySnapshot.forEach(doc => {
        let data = { ...doc.data(), id: doc.id }
        dataArray.push(data)
      })

      setCardAuthorData(() => {
        return dataArray[0]
      })
    }

    getAuthor()
  }
  ////////////////////////////////////////////

  useEffect(() => {
    setErrorDateLineupMessage(false)
    if (fromDate && toDate) {
      if (fromDate > toDate) {
        setErrorDateLineup(true)
        setErrorDateLineupMessage(true)
      } else {
        setErrorDateLineup(false)
        setErrorDateLineupMessage(false)
      }
    } else {
      setErrorDateLineup(true)
    }
  }, [fromDate, toDate])

  const searchFunction = function () {
    setGetDataLoading(true)
    // setFirebaseError(false)
    const testcall = async function () {
      try {
        q.current = query(
          collection(db, 'Bids'),
          where('timestamp', '>=', new Date(fromDate)),
          where('timestamp', '<=', new Date(toDate)),
          orderBy('timestamp', 'desc'),
          limit(limitCount.current)
        )
        //
        let querySnapshot = await getDocs(q.current)
        lastVisible.current = querySnapshot.docs[querySnapshot.docs.length - 1]
        let tempDataArray = []
        querySnapshot.forEach(doc => {
          let data = { ...doc.data(), Id: doc.id }

          tempDataArray.push(data)
        })

        setDataArray([...tempDataArray])
        setGetDataLoading(false)
      } catch (error) {
        setFirebaseError(true)
      }
    }

    testcall()

    return () => {}
  }
  //////////////////////open edit Modal
  const sendEditModalData = function (data) {
    setEditModalDataToSendToModal(data)
    setOpenEditModal(true)
  }

  const loadMoreFunction = async () => {
    ///check
    setGetMoreData(() => true)
    if (!lastVisible.current) {
      setNoMoreData(true)
      setGetMoreData(() => false)
      return
    }

    /////// create Query
    q.value = query(
      collection(db, 'Bids'),
      where('timestamp', '!=', false),
      orderBy('timestamp', 'desc'),
      startAfter(lastVisible.current),
      limit(limitCount.current)
    )
    /////////call Database
    const querySnapshot = await getDocs(q.value)
    lastVisible.current = querySnapshot.docs[querySnapshot.docs.length - 1]

    let tempDataArray = []
    querySnapshot.forEach(doc => {
      let data = { ...doc.data(), Id: doc.id }

      tempDataArray.push(data)
    })

    if (tempDataArray.length < limitCount.current) {
      setNoMoreData(true)

      setGetMoreData(() => false)
    }
    setDataArray(previousData => {
      return [...previousData, ...tempDataArray]
    })

    setGetMoreData(() => false)
  }

  const deleteFunction = async Id => {
    setDeleteFinished([true, Id])

    setLoadingData(true)
    try {
      await deleteDoc(doc(db, 'Bids', Id))
      ///////// loading circle remove
      setDeleteFinished([false, Id])
      ///////////close confirm modal

      ///////////////// to refresh the call for authors from database (this is a dependancy to do the calling from useEffect)
      // setInitialDataLoadingDone(false)
      ///////////resetting the form values
      let newArray = dataArray.filter(object => {
        if (object.Id != Id) {
          return object
        }
      })

      setDataArray(newArray)
      setAlertMessage('تم حذف الملف')
      setAlertMessageTitle('تنبيه :')
      setAlertMessageSucOrFailSwitch(1)

      setDeletedAlert(true)
      setTimeout(() => {
        setDeletedAlert(false)
        setLoadingData(false)
      }, 1500)
    } catch (error) {
      setLoadingData(false)
      setDeleteFinished([false, Id])

      setAlertMessageTitle('Error ')
      setAlertMessage('connecting Database')
      setAlertMessageSucOrFailSwitch(2)
      setDeletedAlert(true)
      setTimeout(() => {
        setDeletedAlert(false)
      }, 1500)
    }
  }
  // setDeleteFinished(false)}
  ///////////////////////////////////////////////////////////
  const closeEditFunction = function () {
    setOpenEditModal(false)
  }
  return (
    <>
      {openEditModal && (
        <EditBidModal
          searchFunction={searchFunction}
          openEditModal={openEditModal}
          editModalDataToSendToModal={editModalDataToSendToModal}
          closeEditFunction={closeEditFunction}
        ></EditBidModal>
      )}
      <Backdrop sx={{ color: '#fff' }} className={' z-40'} open={loadingData}>
        <CircularProgress color="inherit" />
      </Backdrop>
      {/* {modal backdrop ///////////////////////////////////////////////////////////////////////////////////////////////////} */}
      <div
        ref={modalBackdrop}
        className={
          ' fixed  top-0 right-0 left-0 bottom-0 h-screen w-screen bg-black bg-opacity-40 z-10 ' +
          (!showModal ? 'hidden' : 'q')
        }
      ></div>
      <CSSTransition in={showModal} timeout={300} classNames="example">
        {/* {Modal Title //////////////////////////////////////..................... } */}
        <div
          ref={modalButton}
          id="large-modal"
          tabIndex="-1"
          className={
            '  overflow-x-hidden mt-10 fixed top-0 right-0 left-0 z-10 w-full md:inset-0 max-w-screen  overflow-y-scroll h-modal    ' +
            (!showModal ? 'hidden' : 'q')
          }
        >
          <div className="relative overflow-x-hidden p-4 w-full max-w-4xl h-full mx-auto   ">
            <div style={{ minHeight: '500px' }} className="relative bg-white rounded-lg shadow dark:bg-gray-700   pt-3">
              <div className=" mx-20">
                <ul className="flex md:flex-row-reverse flex-wrap text-sm font-medium text-center flex-col-reverse  ">
                  <li className="mr-2">
                    <button
                      onClick={bidTabsToggleFunction}
                      className={
                        'cursor-pointer text-indigo-900 inline-block py-3    px-4 rounded-lg  ' +
                        (tabBids === 1 ? 'bg-yellow-400' : 'hover:text-gray-900 hover:bg-gray-100 ')
                      }
                    >
                      العطاء
                    </button>
                  </li>
                  <li className="mr-2">
                    <button
                      onClick={authorTabsToggleFunction}
                      className={
                        'cursor-pointer text-indigo-900 inline-block py-3    px-4 rounded-lg      ' +
                        (tabBids === 2 ? 'bg-yellow-400' : 'hover:text-gray-900 hover:bg-gray-100 ')
                      }
                    >
                      الناشر
                    </button>
                  </li>
                  <li className="mr-2">
                    <button
                      onClick={filesTabsToggleFunction}
                      className={
                        'cursor-pointer text-indigo-900 inline-block py-3  px-4 rounded-lg     ' +
                        (tabBids === 3 ? 'bg-yellow-400' : 'hover:text-gray-900 hover:bg-gray-100 ')
                      }
                    >
                      الملفات
                    </button>
                  </li>
                  <button
                    onClick={closeFucntion}
                    type="button"
                    className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 mr-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white"
                  >
                    <svg className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                      <path
                        fillRule="evenodd"
                        d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                        clipRule="evenodd"
                      ></path>
                    </svg>
                  </button>
                </ul>
              </div>
              {tabBids === 3 && <TabFiles cardData={cardData} closeFucntion={closeFucntion}></TabFiles>}
              {tabBids === 2 && <TabAuthor closeFucntion={closeFucntion} cardAuthorData={cardAuthorData}></TabAuthor>}
              {tabBids === 1 && (
                <TabBids
                  expiredGlow={expiredGlow}
                  expiry={expiry}
                  cardData={cardData}
                  publishDate={publishDate}
                  closeFucntion={closeFucntion}
                ></TabBids>
              )}{' '}
            </div>
          </div>
        </div>
      </CSSTransition>
      <div className="relative z-50">
        {deletedAlert && (
          <AdminCustomAlert
            alertMessageSucOrFailSwitch={alertMessageSucOrFailSwitch}
            alertMessage={alertMessage}
            alertMessageTitle={alertMessageTitle}
          />
        )}
      </div>
      <div className="flex flex-col text-indigo-900">
        <span className="mt-3 mb-1">من</span>
        <input
          ref={fromDateRef}
          className=" mx-auto w-1/2  placeholder-gray-300 text-center shadow-sm bg-gray-50 border border-gray-300 text-green-500 text-sm rounded-lg focus:ring-[1px] focus:ring-yellow-400/50  focus:border-yellow-400/50 active:border-0  focus:border-0  block   p-2.5  "
          type="date"
          name=""
          id=""
          onChange={e => setFromDate(e.target.value)}
        />
        <span className="mt-3 mb-1">الى</span>
        <input
          ref={toDateRef}
          className="  mx-auto w-1/2  placeholder-gray-300 text-center shadow-sm bg-gray-50 border border-gray-300 text-green-500 text-sm rounded-lg focus:ring-[1px] focus:ring-yellow-400/50  focus:border-yellow-400/50 active:border-0  focus:border-0  block   p-2.5  "
          type="date"
          name=""
          id=""
          onChange={e => setToDate(e.target.value)}
        />
        {errorDateLineupMessage && (
          <span className="text-red-600 mt-3  animate-pulse block"> تم ادخال التاريخ بشكل معكوس </span>
        )}
        <div className="mx-auto flex w-full">
          <button
            onClick={searchFunction}
            disabled={errorDateLineup}
            className=" disabled:bg-gray-400 mx-auto mt-3 w-1/2 sm:w-[130px] flex  text-white  justify-center items-baseline   bg-indigo-900 hover:bg-indigo-800 hover:scale-[1.01] transition focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm   px-5 py-2.5  dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
          >
            {getDataLoading && (
              <div>
                <svg
                  role="status"
                  className="inline mr-2 w-8   text-gray-200 animate-spin dark:text-gray-600 fill-red-600 h-6"
                  viewBox="0 0 100 101"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                    fill="currentColor"
                  />
                  <path
                    d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                    fill="currentFill"
                  />
                </svg>
              </div>
            )}
            <div>
              {' '}
              <span>بحث</span>
            </div>
          </button>
        </div>
        <div className="text-center mt-3 animate-pulse text-red-600">
          {firebaseError && <span>DataBase Error</span>}
        </div>
      </div>
      <div>
        {dataArray.map((entry, i) => {
          return (
            <div className="border   border-gray-300/50 border-dashed">
              <div className="text-left pl-5 text-gray-400">{i}</div>
              <CardAdmin
                sendEditModalData={sendEditModalData}
                deleteFunction={deleteFunction}
                key={entry.Id}
                data={entry}
                deleteFinished={deleteFinished}
                clickedCardData={clickedCardData}
              ></CardAdmin>
            </div>
          )
        })}
      </div>
      <div className="mx-auto" style={{ maxWidth: '600px' }}>
        {/* {load more button////////////////////////////////////////////////////////////} */}
        {dataArray.length > 0 ? (
          <button
            onClick={loadMoreFunction}
            className={
              'btn block mx-auto btn-primary mt-3  text-white ' +
              // (getMoreData ? {loadingsvg} : '') +

              (noMoreData ? ' btn-disabled ' : '') +
              (dataArray.length === 0 ? 'hidden' : '')
            }
          >
            {' '}
            {getMoreData && (
              <svg
                role="status"
                className="inline mr-2 w-8 h-6 text-gray-200 animate-spin dark:text-gray-600 fill-red-600"
                viewBox="0 0 100 101"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                  fill="currentColor"
                />
                <path
                  d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                  fill="currentFill"
                />
              </svg>
            )}{' '}
            {noMoreData ? <span>تم تحميل كافة البيانات</span> : <span>تحميل المزيد</span>}{' '}
          </button>
        ) : (
          <div></div>
        )}
      </div>
    </>
  )
}

export default TabEditDelBid
