import React from 'react'
import { motion } from 'framer-motion'

import { AiOutlineMail } from 'react-icons/ai'
import { MdPeopleOutline } from 'react-icons/md'
import { BiPhoneCall } from 'react-icons/bi'
import { MdWebAsset } from 'react-icons/md'
function TabAuthor({ cardAuthorData, closeFucntion }) {
  const styleObject2 = {
    display: 'inline',
    verticalAlign: 'middle',
    fill: '#CF2020',
    height: '1.5em',
    width: '1.5em',
  }
  if (!cardAuthorData) {
    return <div className=" text-red-500 text-lg text-center mt-10">No Data available for this Author</div>
  }

  return (
    <>
      <motion.div initial={{ x: '-10px', opacity: 0 }} transition={{ duration: 0.4 }} animate={{ x: 0, opacity: 1 }}>
        <div className=" flex  justify-center items-center p-5 rounded-t border-b border-yellow-300 ">
          <div className="font-semibold text-indigo-900   text-2xl">{cardAuthorData.value}</div>
        </div>
        <div className="grid   md:grid-cols-2 grid-cols-1    p-5   ">
          <div className=" self-center justify-self-center md:col-start-1 md:row-span-4 md:row-start-1   ">
            <a href={cardAuthorData.Logo ? cardAuthorData.Logo : []} target="blank">
              <img
                className="object-contain h-56 w-56"
                src={cardAuthorData.Logo ? cardAuthorData.Logo : 'https://via.placeholder.com/250'}
                alt="https://via.placeholder.com/250"
              />
            </a>
          </div>
          <div className="md:mb-4  p-2  text-indigo-900 justify-self-end md:mr-1  md:col-start-2 md:row-start-1">
            <span className="md:text-base  small-text-my-class-allauthor">
              <span className=" font-semibold md:text-xl"> {cardAuthorData.Email && cardAuthorData.Email}</span>
              <span className="md:text-base mx-1 small-text-my-class-title-all">-:</span>
              <span className="md:text-base text-xs small-text-my-class-title-all">البريد</span>
            </span>
            <span className="text-xs small-text-my-class-title-all ml-1 ">
              <AiOutlineMail style={styleObject2} />
            </span>
          </div>
          <div className="md:mb-4  p-2 text-right text-indigo-900 justify-self-end  md:mr-1   md:col-start-2 md:row-start-2">
            <span className="md:text-base   small-text-my-class-allauthor">
              <span className="md:text-base text-xs small-text-my-class-title-all">القطاع</span>
              <span className="md:text-base mx-1 small-text-my-class-title-all">-:</span>
              <span className=" font-semibold md:text-xl"> {cardAuthorData.Section && cardAuthorData.Section}</span>
            </span>
            <span className="text-xs small-text-my-class-title-all ml-1 ">
              <MdPeopleOutline style={styleObject2} />
            </span>
          </div>
          <div className="md:mb-4  p-2  text-indigo-900 justify-self-end  md:mr-1   md:col-start-2 md:row-start-3">
            <span className="md:text-base  small-text-my-class-allauthor">
              <span className=" font-semibold md:text-xl"> {cardAuthorData.Phone && cardAuthorData.Phone}</span>
              <span className="md:text-base mx-1 small-text-my-class-title-all">-:</span>
              <span className="md:text-base text-xs small-text-my-class-title-all">رقم الهاتف</span>
            </span>
            <span className="text-xs small-text-my-class-title-all ml-1 ">
              <BiPhoneCall style={styleObject2} />
            </span>
          </div>
          <div className="md:mb-4  p-2  text-indigo-900 justify-self-end  md:mr-1   md:col-start-2 md:row-start-4">
            <span className="md:text-base small-text-my-class-allauthor">
              <span className=" font-semibold md:text-xl"> {cardAuthorData.Web && cardAuthorData.Web}</span>
              <span className="md:text-base mx-1 small-text-my-class-title-all">-:</span>
              <span className="md:text-base text-xs small-text-my-class-title-all">الموقع الالكتروني</span>
            </span>
            <span className="text-xs small-text-my-class-title-all ml-1 ">
              <MdWebAsset style={styleObject2} />
            </span>
          </div>
        </div>
        <div className="flex justify-center items-center p-6 space-x-2 rounded-b border-t border-yellow-300">
          <button
            onClick={closeFucntion}
            type="button"
            className="text-white bg-indigo-900 hover:bg-indigo-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
          >
            اغلاق
          </button>
        </div>
      </motion.div>
    </>
  )
}

export default TabAuthor
