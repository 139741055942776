import React, { useState } from 'react'
import SelectMenuAuthorAdmin from '../../Components/SelectMenuAuthorAdmin'
import { Fragment, useRef, useEffect } from 'react'
import { Combobox, Transition } from '@headlessui/react'
import { CheckIcon, SelectorIcon } from '@heroicons/react/solid'
import { ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage'
import { db, timestamp, auth, signInWithEmailAndPassword, signOut, storage } from '../../firestore'
import AdminCustomAlert from '../AdminPageComponents/AdminCustomALert'

import Backdrop from '@mui/material/Backdrop'
import CircularProgress from '@mui/material/CircularProgress'
import {
  collection,
  getDocs,
  query,
  where,
  orderBy,
  limit,
  startAfter,
  doc,
  deleteDoc,
  setDoc,
} from 'firebase/firestore'
import PopAlertModalDeleteConfirm from '../../Components/PopAlertModalDeleteConfirm'
import PopAlertModal from '../../Components/PopAlertModal'
import { v4 as uuidv4 } from 'uuid'

import { Dialog } from '@headlessui/react'

function TabEditDelAuthor() {
  ////////file upload ref and function onchange

  const [fileUploadFailMessage, setFileUploadFailMessage] = useState(false)
  const fileInputOne = useRef()
  const fileToUpload = useRef()
  const progress = useRef()
  const fileOneFunction = function (e) {
    fileToUpload.current = e.target.files[0]
  }
  //////

  const [showAlert, setSHowAlert] = useState(false)
  const [alertMessage, setAlertMessage] = useState(false)
  const [alertMessageTitle, setAlertMessageTitle] = useState(false)

  const form = useRef()
  const switcher = useRef(0)
  const [refresher, setRefresher] = useState(true)
  const [deletedAlert, setDeletedAlert] = useState(false)
  let [initialDataLoadingDone, setInitialDataLoadingDone] = useState(false)
  let [somethingInprogress, setSomethingInprogress] = useState(true)
  const [dataArray, setDataArray] = useState([])
  const [selectParent, setSelectParent] = useState({})
  const [fileUploadSizeMessage, setFileUploadSizeMessage] = useState(false)
  let q = useRef()
  ///////////////////////////////modal confrim values
  const [openModalAlert, setOpenModalAlert] = useState(false)
  const [openModalAlertV, setOpenModalAlertV] = useState(false)

  let [Message, setMessage] = useState('')
  let [MessageExtended, setMessageExtended] = useState('')

  let alertMessagetitle = ''
  let alertMessageSubject = ''

  const emailPopMessage = (
    <>
      <Dialog.Title as="h3" className="text-lg text-center font-medium leading-6 text-indigo-900">
        {Message}
      </Dialog.Title>
      <div className="mt-2">
        <p className="text-sm text-gray-500 text-right">{MessageExtended}</p>
      </div>
    </>
  )
  ///////////////////////////////////////////////////////// 2 functions to call for the modal
  const deleteAuthor = function () {
    if (!companyId) {
      return
    }
    switcher.current = 1
    setMessage('تنبيه')
    setMessageExtended('حذف الملف نهائيا؟')
    setOpenModalAlert(true)
  }

  const updateAuthor = function () {
    if (!companyId) {
      return
    }
    switcher.current = 2

    setMessage('تنبيه')
    setMessageExtended('تعديل الملف؟')
    setOpenModalAlert(true)
  }
  ///////////////////////////////////////////////////////////
  const deleteOrUpdateAuthorFunction = function () {
    setFileUploadSizeMessage(false)
    setFileUploadFailMessage(false)
    if (switcher.current === 1) {
      deleteAuthorFunction()
    }
    if (switcher.current === 2) {
      updateAuthorFunction()
    }
  }

  const deleteAuthorFunction = async function () {
    ////backdrop variable
    setSomethingInprogress(false)
    /////delete call to firebase
    alertMessagetitle = 'تنبيه: '
    alertMessageSubject = 'تم شطب الملف'
    try {
      await deleteDoc(doc(db, 'Author', companyId))
      ///////// backdrop remove
      setSomethingInprogress(true)
      ///////////close confirm modal
      setOpenModalAlert(false)
      ///////////////// to refresh the call for authors from database (this is a dependancy to do the calling from useEffect)
      setInitialDataLoadingDone(false)
      ///////////resetting the form values
      clearForm()
      setAlertMessage('تم حذف الملف')
      setAlertMessageTitle('تنبيه :')

      setDeletedAlert(true)
      setTimeout(() => {
        setDeletedAlert(false)
      }, 1500)
    } catch (error) {
      setSomethingInprogress(false)

      alertMessagetitle = 'Error '
      alertMessageSubject = 'connecting Database'
      setDeletedAlert(true)
      setTimeout(() => {
        setDeletedAlert(false)
      }, 1500)
    }
  }
  //////////////////////////////states for getting form values and thier functions
  const [companyName, setCompanyName] = useState('')
  const [companyLabel, setCompanyLabel] = useState('')

  const [companyEmail, setCompanyEmail] = useState('')
  const [companyPhone, setCompanyPhone] = useState('')
  const [companyWeb, setCompanyWeb] = useState('')
  const [companyId, setCompanyId] = useState('')
  const [companyLogo, setCompanyLogo] = useState('')
  const [dataToSave, setDataToSave] = useState({})

  const onChangecompanyName = function (e) {
    setCompanyName(e.target.value)
    setCompanyLabel(e.target.value)
  }
  const onChangecompanyEmail = function (e) {
    setCompanyEmail(e.target.value)
  }
  const onChangecompanyPhone = function (e) {
    setCompanyPhone(e.target.value)
  }
  const onChangecompanyWeb = function (e) {
    setCompanyWeb(e.target.value)
  }
  //////////////////the function called to end data to firestore after all validation passes
  const sendDataToFireStore = async function () {
    const cityRef = doc(db, 'Author', companyId)

    await setDoc(cityRef, dataToSave, { merge: true })
    setAlertMessage('تم تعديل الملف')
    setAlertMessageTitle('تنبيه :')

    setDeletedAlert(true)
    setTimeout(() => {
      setDeletedAlert(false)
    }, 1500)
    //////////////remove backdrop
    setSomethingInprogress(true)
    ////////clear the form value
    clearForm()
    ///////////////// restart first data call by useing the useeffect dependancy
    setInitialDataLoadingDone(false)
    console.log(fileToUpload.current)
    console.log('companyId:', companyId)
  }
  /////////////////clear form function
  const clearForm = function () {
    fileInputOne.current.value = ''
    setSelectParent({})

    setCompanyName('')
    setCompanyLabel('')
    setCompanyEmail('')
    setCompanyPhone('')
    setCompanyWeb('')
    setCompanyId('')
    setCompanyLogo('')
  }
  ////////////////////function passed to parent to change selected value
  const changeSelected = function (selection) {
    setSelectParent(selection)
  }
  const submitForm = function (e) {
    e.preventDefault()
  }
  /////////////////useEffect call All author Data form DB
  useEffect(() => {
    if (initialDataLoadingDone) {
      return
    }
    const firstCall = async function () {
      q.current = query(collection(db, 'Author'), where('value', '!=', ''), orderBy('value', 'desc'))
      //
      let querySnapshot = await getDocs(q.current)

      let tempDataArray = []
      querySnapshot.forEach(doc => {
        let data = { ...doc.data(), Id: doc.id }

        tempDataArray.push(data)
      })

      setInitialDataLoadingDone(true)

      setDataArray([...tempDataArray])
    }

    firstCall()

    return () => {}
  }, [initialDataLoadingDone])
  ////////////////////////////////////////////////////////// change the fileds value each time selected company changes
  useEffect(() => {
    console.log(selectParent)
    fileInputOne.current.value = ''
    setCompanyName(selectParent.value)
    setCompanyLabel(selectParent.value)
    if (selectParent.Email) {
      setCompanyEmail(selectParent.Email)
    } else {
      setCompanyEmail('')
    }
    if (selectParent.Phone) {
      setCompanyPhone(selectParent.Phone)
    } else {
      setCompanyPhone('')
    }
    if (selectParent.Web) {
      setCompanyWeb(selectParent.Web)
    } else {
      setCompanyWeb('')
    }
    if (selectParent.Logo) {
      setCompanyLogo(selectParent.Logo)
    } else {
      setCompanyLogo('')
    }
    setCompanyId(selectParent.Id)
  }, [selectParent])

  ////////////////////////make datatosave object to use with firebase call
  useEffect(() => {
    setDataToSave(prevState => {
      return { ...prevState, value: companyName }
    })
    setDataToSave(prevState => {
      return { ...prevState, label: companyName }
    })
    setDataToSave(prevState => {
      return { ...prevState, Email: companyEmail }
    })
    setDataToSave(prevState => {
      return { ...prevState, Phone: companyPhone }
    })
    setDataToSave(prevState => {
      return { ...prevState, Web: companyWeb }
    })
    setDataToSave(prevState => {
      return { ...prevState, Logo: companyLogo }
    })
  }, [companyName, companyWeb, companyEmail, companyPhone, companyLogo])

  ////////////////////////////////Modal close function
  const closeModalFunctionPassed = () => {
    setOpenModalAlert(false)
    setOpenModalAlertV(false)
  }
  ////////////////////////////////////////////////////////updateAuthorFunction
  const updateAuthorFunction = async function () {
    setSomethingInprogress(false)
    setOpenModalAlert(false)
    if (!companyName || companyName.trim().length === 0) {
      setMessage('ادخال ناقص')
      setMessageExtended('الرجاء ادخال اسم الشركة')
      setSomethingInprogress(true)
      setOpenModalAlert(false)
      setOpenModalAlertV(true)

      return
    }
    if (
      /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(companyEmail) ||
      !companyEmail ||
      companyEmail.trim().length === 0
    ) {
    } else {
      setMessage('ادخال ناقص')
      setMessageExtended('البريد الالكتروني غير صحيح')
      setSomethingInprogress(true)
      setOpenModalAlert(false)
      setOpenModalAlertV(true)
      return
    }
    let re = /^[0-9]*$/

    if (
      (re.test(companyPhone.trim()) && companyPhone.length > 6) ||
      !companyPhone ||
      companyPhone.trim().length === 0
    ) {
    } else {
      setMessage('ادخال ناقص')
      setMessageExtended('رقم الهاتف غير صحيح')
      setSomethingInprogress(true)
      setOpenModalAlert(false)
      setOpenModalAlertV(true)
      return
    }
    if (!fileToUpload.current) {
      ///call function
      sendDataToFireStore()
    } else {
      if (fileToUpload.current.size / 1024 > 2048) {
        setFileUploadSizeMessage(true)
        setSomethingInprogress(true)
        return
      } else {
        let myuuid = uuidv4()
        const storageRef = ref(storage, myuuid)

        const uploadTask = uploadBytesResumable(storageRef, fileToUpload.current)
        uploadTask.on(
          'state_changed',
          snapshot => {
            progress.current = (snapshot.bytesTransferred / snapshot.totalBytes) * 100
            console.log('Upload is ' + progress.current + '% done')
            switch (snapshot.state) {
              case 'paused':
                console.log('Upload is paused')
                break
              case 'running':
                console.log('Upload is running')
                break
            }
          },
          error => {
            setFileUploadFailMessage(true)
            somethingInprogress(true)
            console.log(error.message)
          },
          () => {
            // Handle successful uploads on complete
            // For instance, get the download URL: https://firebasestorage.googleapis.com/...
            getDownloadURL(uploadTask.snapshot.ref).then(downloadURL => {
              console.log('File available at', downloadURL)

              setDataToSave(prevState => {
                return { ...prevState, Logo: downloadURL }
              }, sendDataToFireStore())
              setSomethingInprogress(true)
            })
          }
        )
      }
    }
  }
  return (
    <>
      <PopAlertModal
        className="z-50"
        deleteOrUpdateAuthorFunction={deleteOrUpdateAuthorFunction}
        openModalAlertV={openModalAlertV}
        closeModalFunctionPassed={closeModalFunctionPassed}
      >
        {emailPopMessage}
      </PopAlertModal>
      <PopAlertModalDeleteConfirm
        className="z-50"
        deleteOrUpdateAuthorFunction={deleteOrUpdateAuthorFunction}
        openModalAlert={openModalAlert}
        closeModalFunctionPassed={closeModalFunctionPassed}
      >
        {emailPopMessage}
      </PopAlertModalDeleteConfirm>
      <Backdrop sx={{ color: '#fff', zIndex: theme => theme.zIndex.drawer + 1 }} open={!initialDataLoadingDone}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <Backdrop sx={{ color: '#fff', zIndex: theme => theme.zIndex.drawer + 1 }} open={!somethingInprogress}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <div>
        <SelectMenuAuthorAdmin dataArray={dataArray} changeSelected={changeSelected}></SelectMenuAuthorAdmin>
      </div>
      <form ref={form} className="px-3 mt-10">
        <div className="mb-6">
          <label htmlFor="name" className="  block mb-2 text-sm font-medium text-indigo-900 dark:text-gray-300">
            اسم الشركة
          </label>
          <input
            onChange={onChangecompanyName}
            value={companyName ?? ''}
            type="text"
            id="name"
            required
            className="placeholder-gray-300 text-center shadow-sm bg-gray-50 border border-gray-300 text-green-500 text-sm rounded-lg focus:ring-[1px] focus:ring-yellow-400/50  focus:border-yellow-400/50 active:border-0  focus:border-0  block w-full p-2.5 "
            placeholder="*Required"
          />
        </div>
        <div className="mb-6">
          <label htmlFor="email" className="block mb-2 text-sm font-medium text-indigo-900 dark:text-gray-300">
            {' '}
            البريد الالكتروني
          </label>
          <input
            onChange={onChangecompanyEmail}
            value={companyEmail ?? ''}
            type="email"
            id="email"
            className="placeholder-gray-300 text-center  shadow-sm bg-gray-50 border border-gray-300 text-green-500 text-sm rounded-lg focus:ring-[1px] focus:ring-yellow-400/50  focus:border-yellow-400/50 active:border-0  focus:border-0  block w-full p-2.5  "
            placeholder="example@example.com"
          />
        </div>
        <div className="mb-6">
          <label htmlFor="phone" className="block mb-2 text-sm font-medium text-indigo-900 dark:text-gray-300">
            {' '}
            رقم التلفون
          </label>
          <input
            onChange={onChangecompanyPhone}
            value={companyPhone ?? ''}
            id="phone"
            className="placeholder-gray-300 text-center  shadow-sm bg-gray-50 border border-gray-300 text-green-500 text-sm rounded-lg focus:ring-[1px] focus:ring-yellow-400/50  focus:border-yellow-400/50 active:border-0  focus:border-0  block w-full p-2.5  "
            placeholder="0691111111"
          />
        </div>
        <div className="mb-6">
          <label htmlFor="web" className="block mb-2 text-sm font-medium text-indigo-900 dark:text-gray-300">
            {' '}
            الموقع الالكتروني
          </label>
          <input
            onChange={onChangecompanyWeb}
            value={companyWeb ?? ''}
            id="web"
            className="placeholder-gray-300 text-center shadow-sm bg-gray-50 border border-gray-300 text-green-500 text-sm rounded-lg focus:ring-[1px] focus:ring-yellow-400/50  focus:border-yellow-400/50 active:border-0  focus:border-0  block w-full p-2.5  "
            placeholder="www.google.com"
            // pattern="www://.*.com"
          />
        </div>
        <label className="block mb-2 text-sm font-medium text-indigo-900 dark:text-gray-300" htmlFor="user_avatar">
          تحميل ملف
        </label>
        {
          <input
            ref={fileInputOne}
            onChange={fileOneFunction}
            className="mb-2 block w-full text-sm text-green-500 bg-gray-50 rounded-lg border border-gray-300 cursor-pointer dark:text-gray-400 focus:outline-none focus:border-transparent dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 outline-none focus:ring-[1px] focus:ring-yellow-400/50   active:border-0  focus:border-0 "
            aria-describedby="company_avatar_help"
            id="company_avatar"
            type="file"
            accept="image/*"
          />
        }
      </form>{' '}
      {deletedAlert && <AdminCustomAlert alertMessage={alertMessage} alertMessageTitle={alertMessageTitle} />}
      <div className="w-full">
        <button
          onClick={updateAuthor}
          className="text-white min-w-[100px] inline-block bg-indigo-900 hover:bg-indigo-800 hover:scale-[1.01] transition focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm  sm:w-auto px-5 py-2.5  dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
        >
          تعديل
        </button>
        <button
          onClick={deleteAuthor}
          className="text-white ml-2 min-w-[100px]  inline-block bg-indigo-900 hover:bg-indigo-800 hover:scale-[1.01] transition focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm   sm:w-auto px-5 py-2.5  dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
        >
          حذف
        </button>
      </div>
      <div className=" min-h-8  text-red-600 text-center animate-pulse duration-700">
        {fileUploadSizeMessage && <div>الملف اكبر من 2 ميجا</div>}
      </div>
      <div className=" min-h-8  text-red-600 text-center animate-pulse duration-700">
        {fileUploadFailMessage && <div>مشكلة في تحميل الملف لقاعدة البيانات</div>}
      </div>
    </>
  )
}

export default TabEditDelAuthor
