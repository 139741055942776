import React, { useEffect ,useState} from 'react'
import { doc, query, where, getDoc ,setDoc  } from "firebase/firestore";
import { db } from '../../firestore'
import Backdrop from '@mui/material/Backdrop'
import CircularProgress from '@mui/material/CircularProgress'





function TabPrices() {
  let [prices, setPrices] = useState({Cement:'',Iron:'',Euro5:'',Diesel:''})
  let [loading, setLoading] = useState(false)
  let [errorSaving, setErrorSaving]=useState(false)

  const handleChange = e => {
    const { name, value } = e.target;
     
    setPrices(prevState => ({
        ...prevState,
        [name]: value
    }));
  
};

const saveFunction=(async()=>{
  setErrorSaving(false)
  setLoading(false)
  try {
      await setDoc(doc(db, "Prices", "Today"), {
    Cement: prices.Cement,
    Iron: prices.Iron,
    Euro5: prices.Euro5,
    Diesel:prices.Diesel
    
  });
  setLoading(true)
  } catch (error) {
    setLoading(true)
    setErrorSaving(true)
  }



})
  const getData=(async()=>{
    const q = query(doc(db, "Prices",'Today'))
    const docSnap = await getDoc(q);

    if (docSnap.exists()) {
     setPrices(docSnap.data());
    setLoading(true)
    } else {
      // doc.data() will be undefined in this case
      console.log("Cant Connect");
    }

  })

  useEffect(()=>{
    getData()
   

 
    
  },[])
  return (
    <>
     <Backdrop sx={{ color: '#fff', zIndex: theme => theme.zIndex.drawer + 1 }} open={!loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    
    <label htmlFor="cement" className="block mb-2 text-sm font-medium text-indigo-900 dark:text-gray-300">
            اسمنت    {' '}
          </label>
    <input
    value={prices.Cement}
    onChange={handleChange}
    name="Cement"
    type="text"
    id="cement"
    className="placeholder-gray-300 text-center shadow-sm bg-gray-50 border border-gray-300 text-green-500 text-sm rounded-lg focus:ring-[1px] focus:ring-yellow-400/50  focus:border-yellow-400/50 active:border-0  focus:border-0  block w-28 mx-auto mt-3 p-2.5"
    placeholder="cement"
    required
  />
   <label htmlFor="euro5" className="block mb-2 text-sm font-medium text-indigo-900 dark:text-gray-300 mt-10">
                  يورو5 
          </label>
    <input
    value={prices.Euro5}
    onChange={handleChange}

    type="text"
    id="euro5"
    name="Euro5"
    className="placeholder-gray-300 text-center shadow-sm bg-gray-50 border border-gray-300 text-green-500 text-sm rounded-lg focus:ring-[1px] focus:ring-yellow-400/50  focus:border-yellow-400/50 active:border-0  focus:border-0  block w-28 mx-auto mt-3 p-2.5"
    placeholder="euro5"
    required
  />
     <label htmlFor="iron" className="block mb-2 text-sm font-medium text-indigo-900 dark:text-gray-300 mt-10">
     حديد 
          </label>
    <input
    value={prices.Iron}
    onChange={handleChange}

    type="text"
    id="iron"
    name="Iron"
    className="placeholder-gray-300 text-center shadow-sm bg-gray-50 border border-gray-300 text-green-500 text-sm rounded-lg focus:ring-[1px] focus:ring-yellow-400/50  focus:border-yellow-400/50 active:border-0  focus:border-0  block w-28 mx-auto mt-3 p-2.5"
    placeholder="iron"
    required
  />
     <label htmlFor="diesel" className="block mb-2 text-sm font-medium text-indigo-900 dark:text-gray-300 mt-10">
     ديزل 
          </label>
    <input
    value={prices.Diesel}
    onChange={handleChange}

    type="text"
    id="diesel"
    name="Diesel"
    className="placeholder-gray-300 text-center shadow-sm bg-gray-50 border border-gray-300 text-green-500 text-sm rounded-lg focus:ring-[1px] focus:ring-yellow-400/50  focus:border-yellow-400/50 active:border-0  focus:border-0  block w-28 mx-auto mt-3 p-2.5"
    placeholder="diesel"
    required
  />
   <button
        onClick={saveFunction}
        className="mt-10 text-white bg-indigo-900 hover:bg-indigo-800 hover:scale-[1.01] transition focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5  dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
      >
        حفظ
      </button>
      { errorSaving && <div className=' text-red-400 mt-5'>Error connecting Database</div>}
</>
  )
}

export default TabPrices