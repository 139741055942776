import { Fragment, useEffect, useState } from 'react'
import { Combobox, Transition } from '@headlessui/react'
import { CheckIcon, SelectorIcon } from '@heroicons/react/solid'
const people = [
  {
    value: 'معدل',
    label: 'معدل',
  },
  {
    value: 'جديد',
    label: 'جديد',
  },

  {
    value: 'إعادة نشر',
    label: 'إعادة نشر',
  },
]

export default function Example({ changeSelectedStatus, resetStatus, resetStatusFunction }) {
  const [selected, setSelected] = useState({ value: '' })
  const [query, setQuery] = useState('')

  useEffect(() => {
    if (typeof resetStatus === 'object') {
      setSelected({ value: resetStatus[0], label: resetStatus[1] })
    }

    if (resetStatus === true) {
      setSelected({ value: '' })
      resetStatusFunction()
    }
  }, [resetStatus])

  useEffect(() => {
    changeSelectedStatus(selected)
  }, [selected])

  const filteredPeople =
    query === ''
      ? people
      : people.filter(person =>
          person.value.toLowerCase().replace(/\s+/g, '').includes(query.toLowerCase().replace(/\s+/g, ''))
        )

  return (
    <div id="city" className="w-1/2 mx-auto  relative left-3 z-[4] ">
      <Combobox value={selected} onChange={setSelected}>
        <div className="relative mt-1">
          <div className="relative w-full text-center bg-white rounded-lg shadow-md   cursor-default focus:outline-none focus-visible:ring-2 focus-visible:ring-opacity-75 focus-visible:ring-white focus-visible:ring-offset-teal-300 focus-visible:ring-offset-2 sm:text-sm overflow-hidden">
            <Combobox.Input
              className=" w-full text-center border-none focus:ring-0 py-2 pl-3 pr-10 text-sm leading-5 text-green-500 "
              displayValue={person => person.value}
              onChange={event => setQuery(event.target.value)}
            />
            <Combobox.Button className="absolute inset-y-0 right-0 flex items-center pr-2">
              <SelectorIcon className="w-5 h-5 text-gray-400" aria-hidden="true" />
            </Combobox.Button>
          </div>
          <Transition
            as={Fragment}
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
            afterLeave={() => setQuery('')}
          >
            <Combobox.Options className="absolute w-full py-1 mt-1 overflow-auto text-base bg-white rounded-md shadow-lg max-h-60 ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
              {filteredPeople.length === 0 && query !== '' ? (
                <div className="cursor-default select-none relative py-2 px-4 text-gray-700">Nothing found.</div>
              ) : (
                filteredPeople.map(person => (
                  <Combobox.Option
                    key={person.value}
                    className={({ active }) =>
                      `cursor-default select-none z-20 relative py-2 pl-10 pr-4 ${
                        active ? 'text-white bg-yellow-400/80' : 'text-green-500'
                      }`
                    }
                    value={person}
                  >
                    {({ selected, active }) => (
                      <>
                        <span className={`block truncate ${selected ? 'font-medium' : 'font-normal'}`}>
                          {person.value}
                        </span>
                        {selected ? (
                          <span
                            className={`absolute inset-y-0 left-0 flex items-center pl-3 ${
                              active ? 'text-white' : 'text-yellow-400/80'
                            }`}
                          >
                            <CheckIcon className="w-5 h-5" aria-hidden="true" />
                          </span>
                        ) : null}
                      </>
                    )}
                  </Combobox.Option>
                ))
              )}
            </Combobox.Options>
          </Transition>
        </div>
      </Combobox>
    </div>
  )
}
