import React from 'react'

import { NavLink, useLocation } from 'react-router-dom'
import '../Styles/MenuNavigate.scss'

function MenuNavigate(props) {
  const location = useLocation()

  return (
    <div className=" mt-5 w-full rounded border-2 border-dotted px-2  pb-2  text-center   md:w-44 ">
      {location.pathname !== '/AllBids' && (
        <NavLink
          className={
            ' p-2 scalehover text-white transition-all duration-300  rounded-xl block mt-2  bg-yellow-400 text-center align-middle hover:underline   '
          }
          to="/AllBids"
        >
          <p>كل العطاءات</p>
        </NavLink>
      )}
      {location.pathname !== '/WaterBids' && (
        <NavLink
          className={
            ' p-2 scalehover  transition-all duration-300  rounded-xl block mt-2 text-white  bg-yellow-400 text-center align-middle hover:underline   '
          }
          to="/WaterBids"
        >
          <p>عطاءات المياه والصرف الصحي</p>{' '}
        </NavLink>
      )}
      {location.pathname !== '/BridgesBids' && (
        <NavLink
          className={
            ' p-2 scalehover  transition-all duration-300  rounded-xl block mt-2  text-white  bg-yellow-400 text-center align-middle hover:underline   '
          }
          to="/BridgesBids"
        >
          {' '}
          <p>
            عطاءات الطرق والجسور والانفاق<span></span>
          </p>
        </NavLink>
      )}
      {location.pathname !== '/SpecialBids' && (
        <NavLink
          className={
            ' p-2 scalehover  transition-all duration-300  rounded-xl block mt-2   text-white bg-yellow-400 text-center align-middle hover:underline   '
          }
          to="/SpecialBids"
        >
          <p>عطاءات اشغال متخصصة</p>{' '}
        </NavLink>
      )}
      {location.pathname !== '/ElectricityBids' && (
        <NavLink
          className={
            ' p-2 scalehover  transition-all duration-300  rounded-xl block mt-2  text-white  bg-yellow-400 text-center align-middle hover:underline   '
          }
          to="/ElectricityBids"
        >
          {' '}
          <p>عطاءات مقاولات الكهرباء والميكانيك</p>
        </NavLink>
      )}
      {location.pathname !== '/BuildingsBids' && (
        <NavLink
          className={
            ' p-2 scalehover  transition-all duration-300  rounded-xl block mt-2  text-white  bg-yellow-400 text-center align-middle hover:underline   '
          }
          to="/BuildingsBids"
        >
          {' '}
          <p>عطاءات انشاءات الابنية</p>
        </NavLink>
      )}
    </div>
  )
}

export default MenuNavigate
