import React, { useEffect, useState } from 'react'

import { auth, signOut } from '../firestore'

import styles from '../Styles/AllBids.module.scss'
import { motion } from 'framer-motion'
import AdminTabs from '../Components/AdminTabs'
import TabAddBid from '../Components/AdminPageComponents/TabAddBid'
import TabAddAuthor from '../Components/AdminPageComponents/TabAddAuthor'
import TabEditDelBid from '../Components/AdminPageComponents/TabEditDelBid'
import TabEditDelAuthor from '../Components/AdminPageComponents/TabEditDelAuthor'
import TabPrices from '../Components/AdminPageComponents/TabPrices'

import { useNavigate } from 'react-router-dom'

export default function Admin() {
  let nav = useNavigate()

  const logOutFucntion = async e => {
    e.preventDefault()

    try {
      await signOut(auth)
      nav('/login')
    } catch (error) {
      return 1
    }
  }
  const titleVariant = {
    animate: { y: '-180%', opacity: 1 },
  }
  const titleVariantSmall = {
    animate: { y: '-85%', opacity: 1 },
  }

  const [tabIndex, setTabindex] = useState(1)
  const changeTabFunction = function (tabNumber) {
    setTabindex(tabNumber)
  }
  return (
    <>
      <div className={styles.maincontainer}>
        <div className="  w-full flex justify-start">
          <button
            onClick={logOutFucntion}
            className="p-2 px-4 w-28 bg-indigo-900 outline-none focus:ring active:ring active:ring-red-600 focus:ring-red-600/50 rounded text-white hover:bg-yellow-500 hover:scale-[1.05] transition-all duration-300"
          >
            logout
          </button>
        </div>
        <motion.div
          variants={window.innerWidth < 770 ? titleVariantSmall : titleVariant}
          animate="animate"
          className=" text-indigo-900 font-normal underline   text-2xl block w-full text-center md:text-right  right-10  "
        >
          <span className="bg-slate-100 p-2"> صفحة التحكم</span>
        </motion.div>
        <div className="flex flex-nowrap w-full h-full">
          <div className="w-full text-center  items-center justify-center basis-4/5   bg-gray-50">
            <div className=" my-10">
              {tabIndex === 1 && <TabAddBid></TabAddBid>}
              {tabIndex === 2 && <TabAddAuthor></TabAddAuthor>}
              {tabIndex === 3 && <TabEditDelBid></TabEditDelBid>}
              {tabIndex === 4 && <TabEditDelAuthor></TabEditDelAuthor>}
              {tabIndex === 5 && <TabPrices></TabPrices>}
            </div>
          </div>
          <div className="flex flex-col    items-center min-h-full basis-1/5 bg-green-400  text-center    ">
            <AdminTabs tabIndex={tabIndex} changeTabFunction={changeTabFunction}></AdminTabs>
          </div>
        </div>
      </div>
    </>
  )
}
