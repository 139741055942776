import { motion } from 'framer-motion'
import moment from 'moment'
import { CSSTransition } from 'react-transition-group'
import React, { useState, useEffect, useRef } from 'react'
import { useNavigate } from 'react-router-dom'
import Backdrop from '@mui/material/Backdrop'
import CircularProgress from '@mui/material/CircularProgress'
// import SelectMenuAdminCity from '../SelectMenuAdminCity'
import SelectMenuAdminType from '../Components/SelectMenuAdminType'
import SelectMenuAdminSection from '../Components/SelectMenuAdminSection'
import SelectMenuAdminCity from '../Components/SelectMenuAdminCity'
import SelectMenuAdminStatus from '../Components/SelectMenuAdminStatus'
// import SelectMenuAdminStatus from '../SelectMenuAdminStatus'
import SelectMenuAuthorAdminV2 from '../Components/SelectMenuAuthorAdminV2'
import PopAlertModaladdAuthor from '../Components/PopAlertModaladdAuthor'

// import PopAlertModaladdAuthor from '../../Components/PopAlertModaladdAuthor'
import { Dialog } from '@headlessui/react'
import { AiOutlineCloseCircle } from 'react-icons/ai'
import { ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage'
import { v4 as uuidv4 } from 'uuid'
import { db, auth, signOut, storage } from '../firestore'
import AdminCustomAlert from '../Components/AdminPageComponents/AdminCustomALert'
import AdminCustomAlertRed from '../Components/AdminPageComponents/AdminCustomALertRed'

import {
  collection,
  addDoc,
  getDocs,
  query,
  where,
  orderBy,
  limit,
  startAfter,
  doc,
  deleteDoc,
  setDoc,
} from 'firebase/firestore'
import { data } from 'autoprefixer'

function EditBidModal({ OpenEditModal, closeEditFunction, editModalDataToSendToModal, searchFunction }) {
  const [loadingBackDrop, setLoadingBackDrop] = useState(false)
  const [fileUploadFailMessage, setFileUploadFailMessage] = useState(false)
  const [fileUploadSizeMessage, setFileUploadSizeMessage] = useState(false)
  const [initialType, setInitialType] = useState()
  const [details, setDetails] = useState('')
  const [progress, setProgress] = useState(0)
  let dataToSave = {}
  const titleRef = useRef()

  const [alertMessage, setAlertMessage] = useState(false)
  const [alertMessageTitle, setAlertMessageTitle] = useState(false)
  const [addSuccessAlert, setAddSuccessAlert] = useState(false)
  const [addFailAlert, setAddFailAlert] = useState(false)

  let navigate = useNavigate()
  useEffect(() => {
    console.log(dataToSave)
  }, [dataToSave])

  const [switchervariableOne, setSwitchervariableOne] = useState(false)
  const [switchervariableTwo, setSwitchervariableTwo] = useState(false)
  const [switcherFileSourceTwo, setSwitcherFileSourceTwo] = useState()
  const [switcherFileSourceOne, setSwitcherFileSourceOne] = useState()

  const dateField = useRef()
  const [expiry, setExpiry] = useState()
  const [initialExpiry, setInitialExpiry] = useState()

  const [title, setTitle] = useState('')
  const [number, setNumber] = useState('')
  const [dataArray, setDataArray] = useState([])

  const [selectedCity, setSelectedCity] = useState({})
  const [selectedType, setSelectedType] = useState({})

  const [selectedSection, setSelectedSection] = useState({})
  const [selectedStatus, setSelectedStatus] = useState({})
  const [selected, setSelected] = useState({})

  let [invalidMessage, setInvalidMessage] = useState('')
  let [invalidMessageExtended, setInvalidMessageExtended] = useState('')
  const [openModalAlert, setOpenModalAlert] = useState(false)

  const [resetStatus, setResetStatus] = useState(false)
  const [resetSection, setResetSection] = useState(false)
  const [resetType, setResetType] = useState(false)
  const [resetAuthor, setResetAuthor] = useState(false)
  const [resetCity, setResetCity] = useState(false)

  const [initialDataLoadingDone, setInitialDataLoadingDone] = useState(false)

  const [editButtonLoadingSvg, setEditButtonLoadingSvg] = useState(false)
  const modalBackdrop = useRef()

  console.log(editModalDataToSendToModal.Id)
  const fileInputOneRef = useRef()
  const fileInputTwoRef = useRef()

  const [fileInputOne, setFileInputOne] = useState()
  const [fileInputTwo, setFileInputTwo] = useState()

  const [fileOneDeleted, setFileOneDeleted] = useState(false)
  const [fileTwoDeleted, setFileTwoDeleted] = useState(false)

  const emailPopMessage = (
    <>
      <Dialog.Title as="h3" className="text-lg text-center font-medium leading-6 text-indigo-900">
        {invalidMessage}
      </Dialog.Title>
      <div className="mt-2">
        <p className="text-sm text-gray-500 text-right">{invalidMessageExtended}</p>
      </div>
    </>
  )

  const changeSelectedType = function (selection) {
    setSelectedType(selection)
  }
  ///////////this one is for author
  const changeSelected = function (selection) {
    setSelected(selection)
  }
  //////
  const changeSelectedCity = function (selection) {
    setSelectedCity(selection)
  }
  const changeSelectedSection = function (selection) {
    setSelectedSection(selection)
  }
  const changeSelectedStatus = function (selection) {
    setSelectedStatus(selection)
  }
  let q = useRef()

  useEffect(() => {
    let date = ''
    if (expiry) {
      console.log(expiry)
      dateField.current.valueAsDate = expiry
    }
  }, [expiry])

  const closeModalFunctionPassed = () => {
    setOpenModalAlert(false)
  }

  ////////////call for all authors to send it to the selectauthoeradmin
  useEffect(() => {
    if (initialDataLoadingDone) {
      return
    }
    const firstCall = async function () {
      q.current = query(collection(db, 'Author'), where('value', '!=', ''), orderBy('value', 'desc'))
      //
      let querySnapshot = await getDocs(q.current)

      let tempDataArray = []
      querySnapshot.forEach(doc => {
        let data = { ...doc.data(), Id: doc.id }

        tempDataArray.push(data)
      })

      setInitialDataLoadingDone(true)

      setDataArray([...tempDataArray])
    }

    firstCall()

    return () => {}
  }, [initialDataLoadingDone])

  useEffect(() => {
    if (editModalDataToSendToModal.FileOne) {
      setSwitcherFileSourceOne(editModalDataToSendToModal.FileOne)
    }
    if (editModalDataToSendToModal.FileTwo) {
      setSwitcherFileSourceTwo(editModalDataToSendToModal.FileTwo)
    }
  }, [editModalDataToSendToModal])
  const removeFileOne = function () {
    setSwitcherFileSourceOne('https://via.placeholder.com/192x130?text=File Removed')

    setFileOneDeleted(true)
    console.log(fileInputOne)
    setSwitchervariableOne(true)
  }
  const removeFileTwo = function () {
    // setSwitcherFileSourceTwo(editModalDataToSendToModal.FileOne)
    setSwitcherFileSourceTwo('https://via.placeholder.com/192x130?text=File Removed')
    setFileTwoDeleted(true)
    console.log(fileInputTwo)
    setSwitchervariableTwo(true)
  }
  const returnFileOne = function () {
    setFileOneDeleted(false)
    setSwitcherFileSourceOne(editModalDataToSendToModal.FileOne)

    setSwitchervariableOne(false)
  }
  const returnFileTwo = function () {
    setFileTwoDeleted(false)

    setSwitcherFileSourceTwo(editModalDataToSendToModal.FileTwo)

    setSwitchervariableTwo(false)
  }

  /////////////////////////////
  useEffect(() => {
    /////////first 2 parts
    setTitle(editModalDataToSendToModal.Title)
    setNumber(editModalDataToSendToModal.Number)
    ////////function to send bid type to be selected in selectadmintype
    setResetType(editModalDataToSendToModal.Type)
    setResetCity(editModalDataToSendToModal.City)
    setResetSection(editModalDataToSendToModal.Section)
    setResetStatus(editModalDataToSendToModal.Status)
    setResetAuthor(editModalDataToSendToModal.Author)

    setExpiry(editModalDataToSendToModal.Expiry.toDate())
    setDetails(editModalDataToSendToModal.Subject)
  }, [editModalDataToSendToModal])

  ///////////submit
  const submitHandler = function (e) {
    e.preventDefault()
    setFileUploadSizeMessage(false)

    if (
      titleRef.current.value.trim().length === 0 ||
      !selected.value ||
      !selectedCity.value ||
      !selectedSection.value ||
      !selectedType.value ||
      !selectedStatus.value
    ) {
      setInvalidMessage('تنبيه')
      setInvalidMessageExtended('كل البيانات مطلوبة عدا التفاصيل و تحميل الملفات')
      setOpenModalAlert(true)
      return
    }

    dataToSave.Title = titleRef.current.value.split(' ')
    dataToSave.Author = selected.value
    dataToSave.City = [selectedCity.value, 'all']
    dataToSave.Section = [selectedSection.value, 'all']
    dataToSave.Type = [selectedType.value, 'all']
    dataToSave.Status = [selectedStatus.value, 'all']
    dataToSave.Subject = details
    dataToSave.Number = number
    dataToSave.Expiry = expiry
    dataToSave.User = auth.currentUser.email
    dataToSave.timestamp = editModalDataToSendToModal.timestamp.toDate()
    if (editModalDataToSendToModal.FileOne) {
      dataToSave.FileOne = editModalDataToSendToModal.FileOne
      console.log(dataToSave.FileOne)
    }
    console.log(editModalDataToSendToModal.FileTwo)
    if (editModalDataToSendToModal.FileTwo) {
      dataToSave.FileTwo = editModalDataToSendToModal.FileTwo
      console.log(dataToSave.FileTwo)
    }
    // FileOne: editModalDataToSendToModal.FileOne,
    // FileTwo: editModalDataToSendToModal.FileOne,
    console.log(dataToSave)

    if (!fileInputOne && !fileInputTwo) {
      sendDataFunction()
    } else {
      uploadFilesFunction()
    }
  }
  const sendDataFunction = async () => {
    console.log(dataToSave.FileOne)
    if (fileOneDeleted && !fileInputOne) {
      delete dataToSave.FileOne
      console.log('fileone Deleted')
    }
    if (fileTwoDeleted && !fileInputTwo) {
      delete dataToSave.FileTwo
      console.log('File two  Deleted')
    }

    try {
      console.log(dataToSave)
      const cityRef = doc(db, 'Bids', editModalDataToSendToModal.Id)
      // await setDoc(cityRef, dataToSave, { merge: true })
      await setDoc(cityRef, dataToSave)

      setAlertMessageTitle('تنبيه')
      setAlertMessage('تم تعديل الملف بنجاح!')
      setAddSuccessAlert(true)
      setLoadingBackDrop(false)

      ////resetting files
      setFileInputOne(null)
      setFileInputTwo(null)
      ///resetting files input
      fileInputOneRef.current.value = null
      fileInputTwoRef.current.value = null

      /////////////////resetting values form select menu in child

      setTimeout(() => {
        setAddSuccessAlert(false)
        searchFunction()
        closeEditFunction()
        // window.location.reload()
      }, 2000)
      setTimeout(() => {}, 2500)
    } catch (error) {
      setAlertMessageTitle('تنبيه')
      setAlertMessage('العملية فشلت')
      setAddFailAlert(true)
      setLoadingBackDrop(false)
      console.log(error.message)
      setTimeout(() => {
        setAddFailAlert(false)
      }, 2500)
    }
  }
  const uploadFilesFunction = async function () {
    setLoadingBackDrop(true)
    //////////////data Verification
    if (fileInputOne) {
      if (fileInputOne.size / 1024 > 2048) {
        setFileUploadSizeMessage(true)
        setLoadingBackDrop(false)
        return
      }
    }
    if (fileInputTwo) {
      if (fileInputTwo.size / 1024 > 2048) {
        setFileUploadSizeMessage(true)
        setLoadingBackDrop(false)
        return
      }
    }

    let myuuid = uuidv4()
    const storageRef = ref(storage, myuuid)
    if (fileInputOne) {
      const uploadTask = uploadBytesResumable(storageRef, fileInputOne)
      uploadTask.on(
        'state_changed',
        snapshot => {
          // Observe state change events such as progress, pause, and resume
          // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
          setProgress((snapshot.bytesTransferred / snapshot.totalBytes) * 100)
          console.log('Upload is ' + progress + '% done')
          switch (snapshot.state) {
            case 'paused':
              console.log('Upload is paused')
              break
            case 'running':
              console.log('Upload is running')
              break
          }
        },
        error => {
          setFileUploadFailMessage(true)
          setLoadingBackDrop(false)
          console.log(error.message)
        },
        () => {
          // Handle successful uploads on complete
          // For instance, get the download URL: https://firebasestorage.googleapis.com/...
          getDownloadURL(uploadTask.snapshot.ref).then(downloadURL => {
            console.log('File available at', downloadURL)

            dataToSave.FileOne = downloadURL

            setFileOneDeleted(false)
            if (!fileInputTwo && !editModalDataToSendToModal.FileTwo) {
              delete dataToSave.FileTwo
              console.log('deleted file two')

              sendDataFunction()
              return
            }
            if (!fileInputTwo) {
              sendDataFunction()
              return
            }
            if (fileInputTwo) {
              let myuuid = uuidv4()
              const storageRef = ref(storage, myuuid)
              const uploadTask = uploadBytesResumable(storageRef, fileInputTwo)
              uploadTask.on(
                'state_changed',
                snapshot => {
                  // Observe state change events such as progress, pause, and resume
                  // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
                  setProgress((snapshot.bytesTransferred / snapshot.totalBytes) * 100)
                  console.log('Upload is ' + progress + '% done')
                  switch (snapshot.state) {
                    case 'paused':
                      console.log('Upload is paused')
                      break
                    case 'running':
                      console.log('Upload is running')
                      break
                  }
                },
                error => {
                  setFileUploadFailMessage(true)
                  setLoadingBackDrop(false)
                  console.log(error.message)
                },
                () => {
                  // Handle successful uploads on complete
                  // For instance, get the download URL: https://firebasestorage.googleapis.com/...
                  getDownloadURL(uploadTask.snapshot.ref).then(downloadURL => {
                    console.log('File available at', downloadURL)
                    setFileTwoDeleted(false)

                    dataToSave.FileTwo = downloadURL
                    sendDataFunction()
                  })
                }
              )
            }
          })
        }
      )
    } else if (!fileInputOne && fileInputTwo) {
      if (!fileInputOne && !editModalDataToSendToModal.FileOne) {
        delete dataToSave.FileOne
        console.log('deleted file FileOne')
      }

      if (fileInputTwo) {
        let myuuid = uuidv4()
        const storageRef = ref(storage, myuuid)
        const uploadTask = uploadBytesResumable(storageRef, fileInputTwo)
        uploadTask.on(
          'state_changed',
          snapshot => {
            // Observe state change events such as progress, pause, and resume
            // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
            setProgress((snapshot.bytesTransferred / snapshot.totalBytes) * 100)
            console.log('Upload is ' + progress + '% done')
            switch (snapshot.state) {
              case 'paused':
                console.log('Upload is paused')
                break
              case 'running':
                console.log('Upload is running')
                break
            }
          },
          error => {
            setFileUploadFailMessage(true)
            setLoadingBackDrop(false)
            console.log(error.message)
          },
          () => {
            // Handle successful uploads on complete
            // For instance, get the download URL: https://firebasestorage.googleapis.com/...
            getDownloadURL(uploadTask.snapshot.ref).then(downloadURL => {
              console.log('File available at', downloadURL)
              dataToSave.FileTwo = downloadURL
              setFileTwoDeleted(false)
              sendDataFunction()
            })
          }
        )
      }
    }
  }
  return (
    <>
      <PopAlertModaladdAuthor
        className="z-50"
        openModalAlert={openModalAlert}
        closeModalFunctionPassed={closeModalFunctionPassed}
      >
        {emailPopMessage}
      </PopAlertModaladdAuthor>
      <Backdrop sx={{ color: '#fff', zIndex: theme => theme.zIndex.drawer + 1 }} open={loadingBackDrop}>
        <div className=" absolute top-[60%] left-[48%] z-10">
          <CircularProgress color="inherit" />
          {parseFloat(progress).toFixed(1) + '%'}
        </div>
      </Backdrop>
      <div
        ref={modalBackdrop}
        className={
          ' fixed  top-0 right-0 left-0 bottom-0 h-screen w-screen bg-black bg-opacity-40 z-10 ' +
          (OpenEditModal ? 'hidden' : 'q')
        }
      ></div>
      <CSSTransition in={OpenEditModal} timeout={300} classNames="example">
        {/* {Modal Title //////////////////////////////////////..................... } */}
        <div
          tabIndex="-1"
          className={
            '  overflow-x-hidden   border border-blue-500 fixed top-[15%] rounded-lg md:bottom-[15%] md:left-[10%] md:right-[10%] inset-3  z-10   mx-auto     ' +
            (OpenEditModal ? 'hidden' : 'q')
          }
        >
          <div className="bg-slate-50  min-h-full pt-10 px-5">
            <form className="px-3" onSubmit={submitHandler}>
              <div className="mb-6">
                <label htmlFor="title" className="block mb-2 text-sm font-medium text-indigo-900 dark:text-gray-300">
                  العنوان{' '}
                </label>
                <input
                  ref={titleRef}
                  onChange={e => {
                    ;(titleRef.current.value.match(/^\s*$/) || []).length > 0
                      ? setTitle(e.target.value.trim())
                      : setTitle(e.target.value)
                  }}
                  value={title}
                  type="text"
                  id="title"
                  className="placeholder-gray-300 text-center shadow-sm w-full md:w-2/3 bg-gray-50 border border-gray-300 text-green-500 text-sm rounded-lg focus:ring-[1px] focus:ring-yellow-400/50  focus:border-yellow-400/50 active:border-0  focus:border-0  block mx-auto p-2.5"
                  placeholder="عنوان"
                />
              </div>
              <div className="mb-6">
                <label htmlFor="number" className="block mb-2 text-sm font-medium text-indigo-900 dark:text-gray-300">
                  رقم العطاء{' '}
                </label>
                <input
                  value={number}
                  onChange={e => {
                    ;(number.match(/^\s*$/) || []).length > 0
                      ? setNumber(e.target.value.trim())
                      : setNumber(e.target.value)
                  }}
                  type="text"
                  id="number"
                  className="placeholder-gray-300 text-center shadow-sm bg-gray-50 border border-gray-300 text-green-500 text-sm rounded-lg focus:ring-[1px] focus:ring-yellow-400/50  focus:border-yellow-400/50 active:border-0  focus:border-0  block  w-full md:w-2/3 mx-auto  p-2.5"
                  placeholder="999999"
                />
              </div>
              <div>
                <label htmlFor="type" className="block mb-2 text-sm font-medium text-indigo-900 dark:text-gray-400">
                  نوع العطاء
                </label>
              </div>
              <SelectMenuAdminType resetType={resetType} changeSelectedType={changeSelectedType}></SelectMenuAdminType>
              <div>
                {' '}
                <label
                  htmlFor="selectedAuthor"
                  className="mt-6 block mb-2 text-sm font-medium text-indigo-900 dark:text-gray-400"
                >
                  الناشر
                </label>
              </div>
              <div>
                <SelectMenuAuthorAdminV2
                  id="selectedAuthor"
                  dataArray={dataArray}
                  changeSelected={changeSelected}
                  resetAuthor={resetAuthor}
                ></SelectMenuAuthorAdminV2>
              </div>
              <div className="mt-6">
                <label htmlFor="type" className="block mb-2 text-sm font-medium text-indigo-900 dark:text-gray-400">
                  المدينة
                </label>

                <SelectMenuAdminCity
                  resetCity={resetCity}
                  changeSelectedCity={changeSelectedCity}
                ></SelectMenuAdminCity>
              </div>
              <div className="mt-6">
                <label htmlFor="type" className="block mb-2 text-sm font-medium text-indigo-900 dark:text-gray-400">
                  القطاع
                </label>
                <SelectMenuAdminSection
                  resetSection={resetSection}
                  changeSelectedSection={changeSelectedSection}
                ></SelectMenuAdminSection>
              </div>
              <div className="mt-6">
                <label htmlFor="type" className="block mb-2 text-sm font-medium text-indigo-900 dark:text-gray-400">
                  الحالة
                </label>
                <SelectMenuAdminStatus
                  resetStatus={resetStatus}
                  changeSelectedStatus={changeSelectedStatus}
                ></SelectMenuAdminStatus>
              </div>
              <label
                htmlFor="expired"
                className="mt-6 block mb-2 text-sm font-medium text-indigo-900 dark:text-gray-400"
              >
                تاريخ انتهاء التقديم
              </label>
              <input
                ref={dateField}
                className=" relative left-3 z-[1] placeholder-gray-300 text-center shadow-sm bg-gray-50 border border-gray-300 text-green-500 text-sm rounded-lg focus:ring-[1px] focus:ring-yellow-400/50  focus:border-yellow-400/50 active:border-0  focus:border-0  block mx-auto p-2.5 w-1/2"
                type="date"
                name="expired"
                id=""
                onChange={e => setExpiry(new Date(e.target.value))}
                required
              />
              <label
                htmlFor="details"
                className="mt-6 block mb-2 text-sm font-medium text-indigo-900 dark:text-gray-400"
              >
                التفاصيل
              </label>
              <textarea
                className="relative left-3 z-[3] placeholder-gray-300   shadow-sm bg-gray-50 border border-gray-300 text-green-500 text-sm rounded-lg focus:ring-[1px] focus:ring-yellow-400/50  focus:border-yellow-400/50 active:border-0  focus:border-0  block mx-auto p-2.5 w-1/2 text-right"
                id="details"
                name="details"
                rows="4"
                cols="50"
                type="text"
                value={details}
                onChange={e => {
                  ;(details.match(/^\s*$/) || []).length > 0
                    ? setDetails(e.target.value.trim())
                    : setDetails(e.target.value)
                }}
              ></textarea>
              <div className="divider divide-y-2"></div>
              <div className="mt-9 flex flex-col md:flex-row place-content-center border md:p-6 p-3  space-x-7 ">
                {editModalDataToSendToModal.FileOne && (
                  <>
                    <div className="flex flex-col text-indigo-900">
                      <span>File One</span>
                      <div className="   border pb-2  ">
                        <div className=" w-48 h-48 ">
                          <a target="_blank" href={switcherFileSourceOne}>
                            <img className="h-full border-b" src={switcherFileSourceOne} />
                          </a>
                        </div>
                        {!switchervariableOne && (
                          <button
                            onClick={removeFileOne}
                            className={
                              ' mt-3 btn w-[80px] hover:scale-[1.01] block mx-auto  outline-none border-0 hover:bg-red-600     bg-red-500  text-white '
                            }
                          >
                            {<span>حذف</span>}
                          </button>
                        )}
                        {switchervariableOne && (
                          <button
                            onClick={returnFileOne}
                            className={
                              'mt-3 btn w-[80px] hover:scale-[1.01] block mx-auto  outline-none border-0 hover:bg-red-600     bg-red-500  text-white '
                            }
                          >
                            {<span>اعادة</span>}
                          </button>
                        )}
                      </div>
                    </div>
                  </>
                )}
                {editModalDataToSendToModal.FileTwo && (
                  <>
                    <div className="flex flex-col text-indigo-900">
                      <span>File Two</span>
                      <div className="  border pb-2 ">
                        <div className=" w-48 h-48">
                          <a target="_blank" href={switcherFileSourceTwo}>
                            <img className="h-full border-b" src={switcherFileSourceTwo} />
                          </a>
                        </div>
                        {!switchervariableTwo && (
                          <button
                            onClick={removeFileTwo}
                            className={
                              'mt-3 btn w-[80px] hover:scale-[1.01] block mx-auto  outline-none border-0 hover:bg-red-600     bg-red-500  text-white '
                            }
                          >
                            {<span>حذف</span>}
                          </button>
                        )}
                        {switchervariableTwo && (
                          <button
                            onClick={returnFileTwo}
                            className={
                              'mt-3 btn w-[80px] hover:scale-[1.01] block mx-auto  outline-none border-0 hover:bg-red-600     bg-red-500  text-white '
                            }
                          >
                            {<span>اعادة</span>}
                          </button>
                        )}
                      </div>
                    </div>
                  </>
                )}{' '}
              </div>

              <label className="mt-6 block mb-2 text-sm font-medium text-indigo-900 dark:text-gray-400">
                اضافة أو تغيير الملف الأول
              </label>
              <div className="relative">
                <input
                  ref={fileInputOneRef}
                  onChange={e => setFileInputOne(e.target.files[0])}
                  className=" disabled:bg-gray-700/50 mb-2 block w-full text-sm text-green-500 bg-gray-50 rounded-lg border border-gray-300 cursor-pointer dark:text-gray-400 focus:outline-none focus:border-transparent dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 outline-none focus:ring-[1px] focus:ring-yellow-400/50   active:border-0  focus:border-0 "
                  aria-describedby="user_avatar_help"
                  id="file-one"
                  type="file"
                  accept="application/msword, application/vnd.ms-excel, application/vnd.ms-powerpoint,
          text/plain, application/pdf, image/*"
                />{' '}
                <button
                  type="button"
                  onClick={() => {
                    setFileInputOne()
                    setFileInputTwo()
                    fileInputOneRef.current.value = null
                    fileInputTwoRef.current.value = null
                  }}
                  className="absolute top-[1px] right-0 h-9  text-white bg-indigo-900 hover:bg-indigo-800 hover:scale-[1.01] transition focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5  dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                >
                  <AiOutlineCloseCircle></AiOutlineCloseCircle>
                </button>
              </div>
              <label className="mt-6 block mb-2 text-sm font-medium text-indigo-900 dark:text-gray-400">
                اضافة أو تغيير الملف الثاني
              </label>
              <div className="relative">
                <input
                  ref={fileInputTwoRef}
                  onChange={e => setFileInputTwo(e.target.files[0])}
                  className=" disabled:bg-gray-700/50 mb-2 block w-full text-sm text-green-500 bg-gray-50 rounded-lg border border-gray-300 cursor-pointer dark:text-gray-400 focus:outline-none focus:border-transparent dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 outline-none focus:ring-[1px] focus:ring-yellow-400/50   active:border-0  focus:border-0 "
                  aria-describedby="user_avatar_help"
                  id="file-two"
                  type="file"
                  accept="application/msword, application/vnd.ms-excel, application/vnd.ms-powerpoint,
          text/plain, application/pdf, image/*"
                />{' '}
                <button
                  type="button"
                  onClick={() => {
                    setFileInputTwo()

                    fileInputTwoRef.current.value = null
                  }}
                  className="absolute top-[1px] right-0 h-9  text-white bg-indigo-900 hover:bg-indigo-800 hover:scale-[1.01] transition focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5  dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                >
                  <AiOutlineCloseCircle></AiOutlineCloseCircle>
                </button>
              </div>

              <div className="mt-6">
                <div className=" min-h-8  text-red-600 text-center animate-pulse duration-700">
                  {fileUploadFailMessage && <div>مشكلة في تحميل الملف</div>}
                  {fileUploadSizeMessage && <div>الملف اكبر من 2 ميجا</div>}
                </div>
                {addSuccessAlert && (
                  <AdminCustomAlert alertMessage={alertMessage} alertMessageTitle={alertMessageTitle} />
                )}
                {addFailAlert && (
                  <AdminCustomAlertRed alertMessage={alertMessage} alertMessageTitle={alertMessageTitle} />
                )}
                <button
                  type="submit"
                  className={
                    'mt-3 btn w-[140px] hover:scale-[1.01] block mx-auto  outline-none border-0 hover:bg-yellow-400  mb-3   bg-yellow-400/90 text-white '
                  }
                >
                  {' '}
                  {editButtonLoadingSvg && (
                    <svg
                      role="status"
                      className="inline mr-2 w-8 h-6 text-gray-200 animate-spin dark:text-gray-600 fill-red-600"
                      viewBox="0 0 100 101"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                        fill="currentColor"
                      />
                      <path
                        d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                        fill="currentFill"
                      />
                    </svg>
                  )}{' '}
                  <span> Confirm</span>
                </button>{' '}
              </div>
            </form>

            <div>
              {' '}
              <button
                onClick={closeEditFunction}
                className={
                  'mt-3  btn hover:scale-[1.01] w-[140px] block mx-auto outline-none border-0 hover:bg-red-600     bg-red-500 text-white '
                }
              >
                <span> Close</span>
              </button>{' '}
            </div>
            <div className="  min-h-12"></div>
          </div>
        </div>
      </CSSTransition>
    </>
  )
}

export default EditBidModal
