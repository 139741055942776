import React from 'react'

import '../Styles/MenuNavigate.scss'

function MenuNavigate(props) {
  const { changeTabFunction, tabIndex } = props

  const changeTabOne = function () {
    changeTabFunction(1)
  }
  const changeTabTwo = function () {
    changeTabFunction(2)
  }
  const changeTabThree = function () {
    changeTabFunction(3)
  }
  const changeTabFour = function () {
    changeTabFunction(4)
  }
  const changeTabFive = function () {
    changeTabFunction(5)
  }

  return (
    <div className=" mt-5 w-full rounded border-2 border-dotted px-2  pb-2  text-center   md:w-44 ">
   
      <button
        onClick={changeTabOne}
        className={
          'w-full scalehover mt-2  block rounded-xl  bg-indigo-800 p-2 text-center align-middle  text-white transition-all duration-300 hover:underline  ' +
          (tabIndex === 1 ? 'bg-yellow-400 text-white' : '')
        }
      >
        <p>اضافة عطاء</p>
      </button>
      <button
        onClick={changeTabTwo}
        className={
          'w-full scalehover mt-2  block rounded-xl  bg-indigo-800 p-2 text-center  align-middle text-white transition-all duration-300 hover:underline   ' +
          (tabIndex === 2 ? 'bg-yellow-400 text-white' : '')
        }
      >
        {' '}
        <p>
          اضافة ناشر<span></span>
        </p>
      </button>
      <button
        onClick={changeTabThree}
        className={
          'w-full scalehover mt-2  block rounded-xl  bg-indigo-800 p-2 text-center align-middle  text-white transition-all duration-300 hover:underline   ' +
          (tabIndex === 3 ? 'bg-yellow-400 text-white' : '')
        }
      >
        <p> حذف\تعديل عطاء</p>{' '}
      </button>

      <button
        onClick={changeTabFour}
        className={
          'w-full scalehover mt-2  block rounded-xl  bg-indigo-800 p-2 text-center   align-middle text-white transition-all duration-300 hover:underline   ' +
          (tabIndex === 4 ? 'bg-yellow-400 text-white' : '')
        }
      >
        <p> حذف\تعديل ناشر</p>
      </button>
      <button
        onClick={changeTabFive}
        className={
          'w-full scalehover mt-2  block rounded-xl  bg-indigo-800 p-2 text-center align-middle  text-white transition-all duration-300 hover:underline  ' +
          (tabIndex === 5 ? 'bg-yellow-400 text-white' : '')
        }
      >
        <p> اسعار المواد</p>
      </button>
    </div>
  )
}

export default MenuNavigate
