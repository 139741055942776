import React from 'react'
import { motion } from 'framer-motion'
import { BiCurrentLocation } from 'react-icons/bi'
import { VscTypeHierarchy } from 'react-icons/vsc'
import { BsCalendarCheck } from 'react-icons/bs'
import { BsFillCalendarXFill } from 'react-icons/bs'
import { RiMoneyDollarCircleLine } from 'react-icons/ri'
import { FaHashtag } from 'react-icons/fa'
import { MdOutlineSettingsInputAntenna } from 'react-icons/md'

function TabBids({ cardData, expiry, closeFucntion, publishDate, expiredGlow }) {
  const styleObject2 = {
    display: 'inline',
    verticalAlign: 'middle',
    fill: '#CF2020',
    height: '1.5em',
    width: '1.5em',
  }

  return (
    <>
      <motion.div
        initial={{ x: '-10px', opacity: 0 }}
        transition={{ duration: 0.5, type: 'tween' }}
        animate={{ x: 0, opacity: 1 }}
      >
        <div className="   p-5 rounded-t border-b border-yellow-300  ">
          <div className="  min-h-15 overflow-y-auto text-center md:text-xl text-sm sm:text-base font-semibold text-indigo-900 min-h-16    ml-auto mr-auto">
            {cardData.Title && cardData.Title.join(' ')}
          </div>
        </div>
        {/* {First chapter//////////////////////////////////////..................... } */}
        <div className="   p-6 space-y-6 text-indigo-900 leading-relaxed border-b border-yellow-300 border-x-0 grid grid-cols-1 md:grid-cols-2">
          <div className="left justify-self-end mr-4 md:mr-14 self-end md:col-start-2 md:row-start-1">
            <span className="md:text-base text-xs small-text-my-class-title-all">نوع العطاء</span>
            <span className="md:text-base mx-1 small-text-my-class-title-all">-:</span>
            <span className="md:text-base font-semibold small-text-my-class-all">
              {cardData.Type && cardData.Type[0]}
            </span>
            <span className="text-xs small-text-my-class-title-all ml-1 ">
              <VscTypeHierarchy style={styleObject2} />
            </span>
          </div>
          <div className="left justify-self-end mr-4 md:mr-14 self-center md:col-start-2 md:row-start-2">
            <span className="text-xs small-text-my-class-title-all"> تاريخ النشر</span>

            <span className="mx-1 small-text-my-class-title-all">-:</span>
            <span className="font-semibold small-text-my-class-all">{publishDate}</span>
            <span className="text-xs small-text-my-class-title-all ml-1">
              <BsCalendarCheck style={styleObject2} />
            </span>
          </div>
          <div className="left justify-self-end mr-4 md:mr-14 self-start md:col-start-2 md:row-start-3">
            {' '}
            <span className="text-xs small-text-my-class-title-all"> تاريخ الانتهاء </span>
            <span className="mx-1 small-text-my-class-title-all">-:</span>
            <span className="font-semibold small-text-my-class-all"> {expiry} </span>
            <span className="text-xs small-text-my-class-title-all ml-1">
              <BsFillCalendarXFill style={styleObject2} />
            </span>
          </div>
          <div className="left  justify-self-end mr-4 md:mr-14 self-end md:col-start-1 md:row-start-1">
            {' '}
            <span className="text-xs small-text-my-class-all">الجهة الممولة</span>
            <span className="mx-1 small-text-my-class-title-all">-:</span>
            <span className="font-semibold small-text-my-class-all">{cardData.Section && cardData.Section[0]}</span>
            <span className="text-xs small-text-my-class-title-all ml-1">
              <RiMoneyDollarCircleLine style={styleObject2} />
            </span>
          </div>
          <div className="left justify-self-end mr-4 md:mr-14 self-center md:col-start-1 md:row-start-2">
            {' '}
            <span className="text-xs small-text-my-class-title-all">رقم العطاء</span>{' '}
            <span className="mx-1 small-text-my-class-title-all">-:</span>
            <span className=" font-semibold small-text-my-class-all text-left">
              {cardData.Number && cardData.Number}
            </span>
            <span className="text-xs small-text-my-class-title-all ml-1">
              <FaHashtag style={styleObject2} />
            </span>
          </div>
          <div className="left justify-self-end mr-4 md:mr-14 self-center md:col-start-1 md:row-start-2">
            {' '}
            <span className="text-xs small-text-my-class-title-all">رقم العطاء</span>{' '}
            <span className="mx-1 small-text-my-class-title-all">-:</span>
            <span className="font-semibold small-text-my-class-all">{cardData.Number && cardData.Number}</span>
            <span className="text-xs small-text-my-class-title-all ml-1">
              <FaHashtag style={styleObject2} />
            </span>
          </div>
          <div className="left justify-self-end mr-4 md:mr-14 self-center md:col-start-1 md:row-start-3">
            {' '}
            <span className="text-xs small-text-my-class-title-all">المدينة</span>{' '}
            <span className="mx-1 small-text-my-class-title-all">-:</span>
            <span className="font-semibold small-text-my-class-all">{cardData.City && cardData.City[0]}</span>
            <span className="text-xs small-text-my-class-title-all ml-1">
              <BiCurrentLocation style={styleObject2} />
            </span>
          </div>

          <div className="left justify-self-end mr-4 md:mr-14 self-center md:col-start-2 md:row-start-4">
            {' '}
            <span className="text-xs small-text-my-class-title-all">الناشر</span>{' '}
            <span className="mx-1 small-text-my-class-title-all">-:</span>
            <span className="font-semibold small-text-my-class-all">{cardData.Author && cardData.Author}</span>
            <span className="text-xs small-text-my-class-title-all ml-1">
              <MdOutlineSettingsInputAntenna style={styleObject2} />
            </span>
          </div>
          <div className="left justify-self-end mr-4 md:mr-14 self-center md:col-start-1 md:row-start-4">
            {' '}
            <span className="text-xs small-text-my-class-title-all">التسليم</span>{' '}
            <span className="mx-1 small-text-my-class-title-all">-:</span>
            <span
              className={
                'font-semibold small-text-my-class-all rounded  px-2 ' +
                (!expiredGlow ? 'bg-green-400' : 'bg-red-500 animate-pulse')
              }
            >
              {!expiredGlow ? 'فعال' : 'منتهي'}
            </span>
            <span className="text-xs small-text-my-class-title-all ml-1">
              <svg
                style={styleObject2}
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="white"
                strokeWidth={2}
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M9 12l2 2 4-4M7.835 4.697a3.42 3.42 0 001.946-.806 3.42 3.42 0 014.438 0 3.42 3.42 0 001.946.806 3.42 3.42 0 013.138 3.138 3.42 3.42 0 00.806 1.946 3.42 3.42 0 010 4.438 3.42 3.42 0 00-.806 1.946 3.42 3.42 0 01-3.138 3.138 3.42 3.42 0 00-1.946.806 3.42 3.42 0 01-4.438 0 3.42 3.42 0 00-1.946-.806 3.42 3.42 0 01-3.138-3.138 3.42 3.42 0 00-.806-1.946 3.42 3.42 0 010-4.438 3.42 3.42 0 00.806-1.946 3.42 3.42 0 013.138-3.138z"
                />
              </svg>
            </span>
          </div>

          {/* subject paragraph////////////////////////////////////////////////////// */}
        </div>
        <div className="p-6 space-y-6 text-indigo-900 leading-relaxed border-yellow-300  border-x-0      ">
          <p className="text-base text-right leading-8 text-indigo-900 border-yellow-300 rounded-b p-6 space-x-2 ">
            {cardData.Subject && cardData.Subject}
          </p>
        </div>

        <div className="flex justify-center items-center p-6 space-x-2 rounded-b border-t border-yellow-300">
          <button
            onClick={closeFucntion}
            type="button"
            className="text-white bg-indigo-900 hover:bg-indigo-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
          >
            اغلاق
          </button>
        </div>
      </motion.div>
    </>
  )
}

export default TabBids
