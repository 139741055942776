import { Fragment, useState, useRef, useEffect } from 'react'
import { Combobox, Transition } from '@headlessui/react'
import { CheckIcon, SelectorIcon } from '@heroicons/react/solid'

export default function Example(props) {
  const { changeSelected, dataArray, resetAuthor } = props
  const isMounted = useRef(false)
  const [selected, setSelected] = useState(dataArray[0])

  const [query, setQuery] = useState('')

  let q = useRef()
  /////////////////function to send data to TabEditDelAuthor to change selected

  const filteredPeople =
    query === ''
      ? dataArray
      : dataArray.filter(company =>
          company.value.toLowerCase().replace(/\s+/g, '').includes(query.toLowerCase().replace(/\s+/g, ''))
        )
  useEffect(() => {
    if (isMounted.current === false) {
      setSelected({ value: '' })
    }
  }, [dataArray])
  useEffect(() => {
    isMounted.current === true ? changeSelected(selected) : (isMounted.current = true)
  }, [selected])

  useEffect(() => {
    if (typeof resetAuthor === 'string') {
      setSelected({ value: resetAuthor })
    }
  }, [resetAuthor])

  return (
    <>
      <div className=" w-1/2 text-center left-3 relative mx-auto z-[7]">
        <Combobox value={selected} onChange={setSelected}>
          <div className="relative mt-1 ">
            <div className="relative w-full text-center bg-white rounded-lg shadow-md cursor-default focus:outline-none focus-visible:ring-2 focus-visible:ring-opacity-75 focus-visible:ring-white focus-visible:ring-offset-teal-300 focus-visible:ring-offset-2 sm:text-sm overflow-hidden">
              <Combobox.Input
                className="w-full text-center border-none focus:ring-0 py-2 pl-3 pr-10 text-sm leading-5 text-green-500"
                displayValue={dataArray => dataArray.value}
                onChange={event => setQuery(event.target.value)}
              />
              <Combobox.Button className="absolute inset-y-0 right-0 flex items-center pr-2">
                <SelectorIcon className="w-5 h-5 text-gray-400" aria-hidden="true" />
              </Combobox.Button>
            </div>
            <Transition
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
              afterLeave={() => setQuery('')}
            >
              <Combobox.Options className="absolute w-full py-1 mt-1 overflow-auto text-base bg-white rounded-md shadow-lg max-h-60 ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                {filteredPeople.length === 0 && query !== '' ? (
                  <div className="cursor-default select-none relative py-2 px-4 text-green-500">Nothing found.</div>
                ) : (
                  filteredPeople.map(person => (
                    <Combobox.Option
                      key={person.Id}
                      className={({ active }) =>
                        `cursor-default select-none relative  py-2 pl-10 pr-4 ${
                          active ? 'text-white bg-yellow-400/80' : 'text-green-500'
                        }`
                      }
                      value={person}
                    >
                      {({ selected, active }) => (
                        <>
                          <span className={`block truncate ${selected ? 'font-medium' : 'font-normal'}`}>
                            {person.value}
                          </span>
                          {selected ? (
                            <span
                              className={`absolute inset-y-0 left-0 flex items-center pl-3 ${
                                active ? 'text-white' : 'text-yellow-400/80'
                              }`}
                            >
                              <CheckIcon className="w-5 h-5" aria-hidden="true" />
                            </span>
                          ) : null}
                        </>
                      )}
                    </Combobox.Option>
                  ))
                )}
              </Combobox.Options>
            </Transition>
          </div>
        </Combobox>
      </div>{' '}
    </>
  )
}
