import React from "react";

import styles from "../Styles/Home.module.scss";
import { useNavigate } from "react-router-dom";
import  { useEffect ,useState} from 'react'
import { doc, query, where, getDoc ,setDoc  } from "firebase/firestore";
import { db } from '../firestore'

import { GiArchBridge } from "react-icons/gi";
import { AiTwotoneSetting } from "react-icons/ai";
import { MdOutlineElectricalServices } from "react-icons/md";
import { BiBuildingHouse } from "react-icons/bi";
import { GiTeacher } from "react-icons/gi";
import { FaGasPump } from "react-icons/fa";




import {MdWater} from "react-icons/md";

export default function Home() {
 
  let [prices, setPrices] = useState({Cement:'',Iron:'',Euro5:'',Diesel:''})

 
  const navigate = useNavigate();

  const navigateHandleWater = () => {
    navigate("/WaterBids");
  };
  const navigateHandleBridge = () => {
    navigate("/BridgesBids");
  };
  const navigateHandleSpecial = () => {
    navigate("/SpecialBids");
  };
  const navigateHandleElectricity = () => {
    navigate("/ElectricityBids");
  };
  const navigateHandleBuildings = () => {
    navigate("/BuildingsBids");
  };
  const navigateHandleAll = () => {
    navigate("/AllBids");
  };
  const getData=(async()=>{
    const q = query(doc(db, "Prices",'Today'))
    const docSnap = await getDoc(q);

    if (docSnap.exists()) {
     setPrices(docSnap.data());
    setLoading(true)
    } else {
      // doc.data() will be undefined in this case
      console.log("Cant Connect");
    }

  })

  useEffect(()=>{
    getData()
   

 
    
  },[])

  return (
    <>
   
      <div className={styles.hero}><div className={styles.wrap}>    <div className={styles.maintitle}>موقع العطاءات الأردنية</div>
        <div className={styles.secondtitle}>
          يتم جمع كل العطاءات التي تنشر في الاردن في موقع واحد
        </div></div>
      
      </div>
      <div className=' max-w-[1300px] mx-auto w-full mt-10 border-[1px] flex space-y-3 md:space-y-0 flex-col md:flex-row flex-wrap flex-shrink-0 border-gray-300 rounded     items-center   justify-around	'> 
      <div className="     space-x-1 text-black flex"><span className="self-center">{prices.Euro5}</span> <span className="self-center">Euro5</span>  <img src={require('../Assets/euroD.svg').default} alt='mySvgImage' /></div>
     <div className="     space-x-1 text-black flex"><span className="self-center">{prices.Diesel}</span><span className="self-center">Diesel</span>  <img src={require('../Assets/fuelone.svg').default} alt='mySvgImage' /></div>
     <div className="   space-x-1 text-black flex"><span className="self-center">{prices.Cement}</span> <span className="self-center">Cement</span>      <img src={require('../Assets/cement.svg').default} alt='mySvgImage' /></div>
 <div className="  space-x-1 text-black flex"><span className="self-center">{prices.Iron}</span> <span className="self-center">Iron</span>       <img src={require('../Assets/iron.svg').default} alt='mySvgImage' /></div>

      </div>
      <div>
        <div className={styles.title}>
          {" "}
          <span>-:</span> <span>البحث بنوع العطاء</span>
        </div>
        
        <div className={ styles.maincontainer}>
          <div className={'mx-auto ' + styles.card} onClick={navigateHandleWater}>
            <MdWater className={styles.svgtester} />
            <p>عطاءات المياه والصرف الصحي</p>
          </div>

          <div className={' mx-auto  '+ styles.card} onClick={navigateHandleBridge}>
            <GiArchBridge className={styles.svgtester} />
            <p>عطاءات الطرق والجسور والانفاق</p>
          </div>
          <div className={' row-start-1 md:col-start-2 xl:col-start-3  mx-auto    ' +styles.card} onClick={navigateHandleAll}>
            <AiTwotoneSetting className={styles.svgtester} />
            <p>كل العطاءات</p>
          </div>

          <div className={' mx-auto  ' + styles.card} onClick={navigateHandleSpecial}>
            <GiTeacher className={styles.svgtester} />
            <p>عطاءات اشغال متخصصة</p>
          </div>
          <div className={' mx-auto  ' + styles.card} onClick={navigateHandleElectricity}>
            <MdOutlineElectricalServices className={styles.svgtester} />
            <p>عطاءات مقاولات الكهرباء والميكانيك</p>
          </div>
         
          <div className={' mx-auto  ' + styles.card} onClick={navigateHandleBuildings}>
            <BiBuildingHouse className={styles.svgtester} />
            <p>عطاءات انشاءات الابنية</p>
          </div>
        </div>
      
        
      </div>
    </>
  );
}
