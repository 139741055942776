import React from 'react'
import { NavLink, useLocation } from 'react-router-dom'
import styles from '../Styles/NavBar.module.scss'
import { ReactComponent as Logo } from '../Assets/builder3.svg'

import { useState } from 'react'
import { useRef, useEffect } from 'react'

export default function NavBar() {
  const filler = useRef(null)
  const location = useLocation()

  // const [offset, setOffset] = useState(0);
  const [scrollClass, setScrollClass] = useState(false)

  // useEffect(() => {
  //   window.onscroll = () => {
  //     setOffset(window.pageYOffset);
  //     if(offset>=50){ setScrollClass(true)}else{setScrollClass(false) }

  //   };
  // }, [offset]);

  //////////////// intersect observer to add shadow when scrolling
  useEffect(() => {
    if (filler.current == null) {
      return
    }
    const randomVar = filler.current
    const observer = new IntersectionObserver(entries => {
      const myTarget = entries[0]
      if (!myTarget.isIntersecting) {
        setScrollClass(true)
      } else {
        setScrollClass(false)
      }
    }, {})
    const intersectfunction = async function () {
      observer.observe(randomVar)
    }

    intersectfunction()

    return () => {
      observer.unobserve(randomVar)
    }
  }, [filler])

  return (
    <>
      <div>
        <div id="filler" ref={filler} style={{ borderBottom: '1px solid transparent' }}></div>
        <nav className={`${styles.navmain} ${scrollClass ? styles.navmainmoved : null}`}>
          <ul className={styles.flexnav}>
            <NavLink to="/Admin" className={styles.linkslogo}>
              <Logo style={{ display: 'block' }} />
            </NavLink>
            <NavLink className={`   ${location.pathname === '/' ? styles.activelink : null} ${styles.links}`} to="/">
              الصفحة الرئيسية
            </NavLink>
            <NavLink
              className={`   ${location.pathname === '/ContactUs' ? styles.activelink : null} ${styles.links}`}
              to="/ContactUs"
            >
              اتصل بنا
            </NavLink>
          </ul>
        </nav>
      </div>
    </>
  )
}
