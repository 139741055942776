// import styles from "../Styles/Card.module.scss";
import '../Styles/Card.scss'
import { BiCurrentLocation } from 'react-icons/bi'
import { VscTypeHierarchy } from 'react-icons/vsc'
import { BsCalendarCheck } from 'react-icons/bs'
import { BsFillCalendarXFill } from 'react-icons/bs'
import { RiMoneyDollarCircleLine } from 'react-icons/ri'
import { FaHashtag } from 'react-icons/fa'
import PopAlertModalDeleteConfirm from '../Components/PopAlertModalDeleteConfirm'
import { Dialog } from '@headlessui/react'

import React, { useEffect, useState } from 'react'
import EditBidModal from './EditBidModal'

export default function Card(props) {
  let [Message, setMessage] = useState('')
  let [MessageExtended, setMessageExtended] = useState('')
  const [openModalAlert, setOpenModalAlert] = useState(false)
  const { data, deleteFunction, deleteFinished, sendEditModalData } = props
  const { timestamp, Expiry, Title } = data
  let titleAfterArray = Title.join(' ')

  const [deleteVariable, setDeleteVariable] = useState(false)

  useEffect(() => {
    if (deleteFinished[0] === true && deleteFinished[1] == data.Id) {
      setDeleteVariable(true)
    } else {
      setDeleteVariable(false)
    }
  }, [deleteFinished])

  //////////////////////////////////
  const emailPopMessage = (
    <>
      <Dialog.Title as="h3" className="text-lg text-center font-medium leading-6 text-indigo-900">
        {Message}
      </Dialog.Title>
      <div className="mt-2">
        <p className="text-sm text-gray-500 text-right">{MessageExtended}</p>
      </div>
    </>
  )

  const publishDate = new Date(timestamp.toDate().toDateString()).toLocaleDateString('ar-EG', {
    weekday: 'long',
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  })

  const expiry = new Date(Expiry.toDate().toDateString()).toLocaleDateString('ar-EG', {
    weekday: 'long',
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  })

  const styleObject = {
    display: 'inline',
    verticalAlign: 'bottom',
    fill: '#CF2020',
    height: '1.5em',
    width: '1.5em',
  }
  const styleObject2 = {
    display: 'inline',
    verticalAlign: 'middle',
    fill: '#CF2020',
    height: '1.5em',
    width: '1.5em',
  }
  ///////////function to send data using the fuction sent by props

  // console.log(Id)
  const sendData = function () {
    props.clickedCardData(data)
  }
  const sendDataDeleteFunction = function () {
    setMessage('تنبيه')
    setMessageExtended('حذف الملف نهائيا؟')
    setOpenModalAlert(true)

    // deleteFunction(data.Id)
  }
  const sendOpenEditModalFunction = function () {
    sendEditModalData(data)
  }
  const deleteOrUpdateAuthorFunction = function () {
    setOpenModalAlert(false)
    deleteFunction(data.Id)
    console.log('deleteplease')
  }
  ////////////////////////////////Modal close function
  const closeModalFunctionPassed = () => {
    setOpenModalAlert(false)
  }

  return (
    <>
      <PopAlertModalDeleteConfirm
        className="z-50"
        openModalAlert={openModalAlert}
        closeModalFunctionPassed={closeModalFunctionPassed}
        deleteOrUpdateAuthorFunction={deleteOrUpdateAuthorFunction}
      >
        {emailPopMessage}
      </PopAlertModalDeleteConfirm>
      <div
        onClick={sendData}
        className="rounded-md border-primary border-2 card-main shadow-md mx-auto "
        style={{ maxWidth: '600px', minHeight: '200px' }}
      >
        <div className="divider-mine"></div>
        <div className="grid grid-cols-4 gap-4">
          <div className="text-center mt-4 font-semibold md:font-semibold title col-start-2 col-span-2  overflow-hidden ">
            {titleAfterArray.length > 20 ? titleAfterArray.substring(0, 20) + '...' : titleAfterArray}
          </div>
          <div className="mt-4 col-end-5 md:self-end md:justify-self-end justify-self-center  mr-4 md:mr-12  title text-xs leading-5">
            {data.City[0]} <BiCurrentLocation style={styleObject} />{' '}
          </div>
        </div>
        <div className="dividertest my-4"></div>
        <div className="wrapper grid md:grid-cols-2 grid-cols-1 grid-rows-3 gap-2 pb-2">
          <div className="left md:justify-self-end justify-self-center mr-4 md:mr-14 md:self-end md:col-start-2 md:row-start-1">
            <span className="text-xs small-text-my-class-title">نوع العطاء</span>
            <span className="mx-1 small-text-my-class-title">-:</span>
            <span className="font-semibold small-text-my-class">{data.Type[0]}</span>
            <span className="text-xs small-text-my-class-title ml-1 ">
              <VscTypeHierarchy style={styleObject2} />
            </span>
          </div>
          <div className="left md:justify-self-end justify-self-center mr-4 md:mr-14 self-center md:col-start-2 md:row-start-2">
            <span className="text-xs small-text-my-class-title"> تاريخ النشر</span>

            <span className="mx-1 small-text-my-class-title">-:</span>
            <span className="font-semibold small-text-my-class">{publishDate}</span>
            <span className="text-xs small-text-my-class-title ml-1">
              <BsCalendarCheck style={styleObject2} />
            </span>
          </div>
          <div className="left md:justify-self-end justify-self-center mr-4 md:mr-14 self-start md:col-start-2 md:row-start-3">
            {' '}
            <span className="text-xs small-text-my-class-title"> تاريخ الانتهاء </span>
            <span className="mx-1 small-text-my-class-title">-:</span>
            <span className="font-semibold small-text-my-class">{expiry}</span>
            <span className="text-xs small-text-my-class-title ml-1">
              <BsFillCalendarXFill style={styleObject2} />
            </span>
          </div>
          <div className="left  md:justify-self-end justify-self-center mr-4 md:mr-14 md:self-end md:col-start-1 md:row-start-1">
            {' '}
            <span className="text-xs small-text-my-class-title">الجهة الممولة</span>
            <span className="mx-1 small-text-my-class-title">-:</span>
            <span className="font-semibold small-text-my-class">{data.Section[0]}</span>
            <span className="text-xs small-text-my-class-title ml-1">
              <RiMoneyDollarCircleLine style={styleObject2} />
            </span>
          </div>
          <div className="left md:justify-self-end justify-self-center mr-4 md:mr-14 self-center md:col-start-1 md:row-start-2">
            {' '}
            <span className="text-xs small-text-my-class-title">رقم العطاء</span>{' '}
            <span className="mx-1 small-text-my-class-title">-:</span>
            <span className="font-semibold small-text-my-class">{data.Number}</span>
            <span className="text-xs small-text-my-class-title ml-1">
              <FaHashtag style={styleObject2} />
            </span>
          </div>
        </div>
      </div>
      <button
        onClick={sendOpenEditModalFunction}
        className={
          'mt-3 btn w-[140px] hover:scale-[1.01] block mx-auto  outline-none border-0 hover:bg-yellow-400  mb-3   bg-yellow-400/90 text-white '
        }
      >
        {' '}
        {deleteVariable && (
          <svg
            role="status"
            className="inline mr-2 w-8 h-6 text-gray-200 animate-spin dark:text-gray-600 fill-red-600"
            viewBox="0 0 100 101"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
              fill="currentColor"
            />
            <path
              d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
              fill="currentFill"
            />
          </svg>
        )}{' '}
        <span> Edit</span>
      </button>{' '}
      <button
        onClick={sendDataDeleteFunction}
        className={
          'mt-3 btn w-[140px] hover:scale-[1.01] block mx-auto outline-none border-0 hover:bg-red-600 mb-3   bg-red-500 text-white '
        }
      >
        {' '}
        {deleteVariable && (
          <svg
            role="status"
            className="inline mr-2 w-8 h-6 text-gray-200 animate-spin dark:text-gray-600 fill-red-600"
            viewBox="0 0 100 101"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
              fill="currentColor"
            />
            <path
              d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
              fill="currentFill"
            />
          </svg>
        )}{' '}
        <span>حذف الملف</span>
      </button>{' '}
    </>
  )
}
