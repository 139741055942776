import { initializeApp } from 'firebase/app'

import { getAuth, signInWithEmailAndPassword, signOut } from 'firebase/auth'

import { getFirestore, serverTimestamp } from 'firebase/firestore'
import { getStorage } from 'firebase/storage'

const firebaseConfig = {
  apiKey: "AIzaSyBsHaDAOvC_E73Am1R44HqYPBAKLbCFvvM",
  authDomain: "obuilderjordan.firebaseapp.com",
  projectId: "obuilderjordan",
  storageBucket: "obuilderjordan.appspot.com",
  messagingSenderId: "282397358648",
  appId: "1:282397358648:web:0e092a00c247800d7c84cd"
};


const app = initializeApp(firebaseConfig)

//   init firebase service connect

const db = getFirestore()
const timestamp = serverTimestamp()
const auth = getAuth(app)
const storage = getStorage(app)

export { db, timestamp, auth, signInWithEmailAndPassword, signOut, storage }
